<section class="mt-4 card card-body bg-white border-0 shadow-sm">
  <h3 class="h4">Approved Areas</h3>
  <div class="row">
    <div class="table-container d-flex flex-column mb-2 mt-3 mx-2">
      @if (approvedAreas.length) {
      <table class="table">
        <thead>
          <tr>
            <th class="col" scope="col">Area Name</th>
            <th class="col-6" scope="col">Applies To</th>
          </tr>
        </thead>
        <tbody>
          @for(area of approvedAreas; track area.personAreaId) {
            <tr scope="row" class="area-row">
              <td class="col">{{area.areaName}}</td>
              <td class="col-6">
                <div class="d-flex flex-wrap">
                  @for(certificate of area.appliesTo; track certificate) {
                  <span class="badge badge-primary me-2 mb-2">{{ certificate }}</span>
                  }
                </div>
              </td>
            </tr>
          }
        </tbody>
      </table>
      }
      @else {
        <p class="lead">This educator does not have any approved areas.</p>
      }
    </div>
  </div>
</section>
