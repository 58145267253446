<h1 class="h3 card-title text-center my-4">ADEConnect Login</h1>
<p class="fs-6">
  This system utilizes the ADEConnect platform for authentication. If you have previously accessed other
  Arizona Department of Education applications, such as AzED Cert, ESA, APLD, or GeniusEMS, you likely already have
  an ADEConnect account. In that case, you can log in using your existing credentials below.
  <span class="fw-bold">If you do not have an ADEConnect account, click the link below to register.</span>
</p>

<button class="btn btn-lg btn-primary w-100 mt-2" (click)="login()">Login With Existing Account</button>
@if(registrationLink) {
  <div class="text-center mt-2">
    <a class="link-opacity-75" rel="noopener" href="{{registrationLink}}" target="_blank">Register a new ADEConnect account here.</a>
  </div>
}
