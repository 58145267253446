import { Component, Input } from '@angular/core';

@Component({
  selector: 'edportal-standard-header',
  template: `<h1>{{mainHeading}}</h1>
  <div class="lead fs-4">
    {{subHeading}}
  </div>`,
})
export class StandardHeaderComponent {
  @Input()
  mainHeading!: string;

  @Input()
  subHeading!: string;
}
