import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'edportal-uploader',
  templateUrl: './uploader.component.html',
  styles: ``
})
export class UploaderComponent {
  @Input() helpText: string = 'Select a document from your computer to upload.';
  @Input() id!: string;
  @Output() fileSelected = new EventEmitter<IFileUploadResult>();

  @ViewChild('uploadInput') uploadInput!: ElementRef;

  get inputId() {
    return !!this.id ? this.id : 'formFile';
  }

  get formFileHelpId() {
    return this.inputId + 'Help';
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (e: any) => {
        const base64Content = e.target.result.split(',')[1];

        this.fileSelected.emit({
          fileName: file.name,
          content: base64Content
        });
      }
    };
  }

  clearInput() {
    this.uploadInput.nativeElement.value = null;
  }
}

export interface IFileUploadResult {
  fileName: string;
  content: string;
}
