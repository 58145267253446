import { Component } from '@angular/core';
import { AuthService } from 'src/auth/services/auth.service';
import { FingerprintCardValidityStatus } from 'src/shared/constants';
import { ProfileClient } from 'src/shared/services/api.service';
import { ConfigService } from 'src/shared/services/config.service';
import { edPortalTerms } from 'src/shared/term-explanations';

@Component({
  selector: 'edportal-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent {
  isExpanded = false;
  ein!: string;
  educatorName!: string;

  einExplanation: string = edPortalTerms.ein;
  fingerprintValidityExplanation: string = edPortalTerms.fingerprintValidity;
  fingerprintValidityStatus!: string;
  fingerprintCardValidityStatus = FingerprintCardValidityStatus;
  environment?: string;

  constructor(private authService: AuthService, private profileClient: ProfileClient, private configService: ConfigService) {
    this.environment = this.configService.config.environment;
  }

  ngOnInit() {
    this.profileClient.getEducatorProfileInfo().subscribe(x => {
      this.ein = x.ein!;
      this.educatorName = x.name!;
      this.fingerprintValidityStatus = x.fingerprintCardValidityStatus!;
    });
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  logout(event: Event): void {
    event.preventDefault();

    this.authService.logout();
  }
}
