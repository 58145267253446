<div class="text-center mb-3">
  <h1 class="h4">Identity Verification</h1>
  <div class="lead">
    Thanks for the input. We need to collect a little more to confirm your identity.
  </div>
</div>
<form #stepTwoForm="ngForm" autocomplete="off">
  <div class="form-group">
    <label class="form-label" for="fingerprintCardNumber">Fingerprint Card Number</label>
    <input #fingerprintCardNumber="ngModel" [(ngModel)]="stepTwoCommand.fingerprintCardNumber" class="form-control"
      name="fingerprintCardNumber" id="fingerprintCardNumber" type="text" />
    <div validationErrors [for]="fingerprintCardNumber.control" [errorEmitter]="errorEmitter"></div>
  </div>
</form>
<div class="d-grid mt-4">
  <button class="btn btn-primary mx-2" (click)="onContinue()">Continue</button>
</div>
