<div class="form-group">
  <label class="form-label" for="role-select">Educator Search</label>
  <ng-select [items]="educators$ | async" bindLabel="display" [trackByFn]="trackByFn" [minTermLength]="minLengthTerm"
    [loading]="educatorsLoading" typeToSearchText="Start typing an EIN" [typeahead]="einSearchInput$"
    [(ngModel)]="selectedEducator" (change)="onEducatorChange()" labelForId="role-select">
  </ng-select>
</div>


<div *ngIf="selectedEducator" class="card">
  <div class="card-body">
    <h5 class="card-title">Educator Information</h5>
    <dl class="row">
      <dt class="col-sm-5">EIN:</dt>
      <dd class="col-sm-7">{{selectedEducator.ein}}</dd>
      <dt class="col-sm-5">Name:</dt>
      <dd class="col-sm-7">{{selectedEducator.name}}</dd>
      <dt class="col-sm-5">SSN:</dt>
      <dd class="col-sm-7">{{selectedEducator.ssn}}</dd>
      <dt class="col-sm-5">Alternate Names:</dt>
      <dd class="col-sm-7">{{selectedEducator.alternateNames.join(', ')}}</dd>
      <dt class="col-sm-5">Date of Birth:</dt>
      <dd class="col-sm-7">{{selectedEducator.dateOfBirth}}</dd>
      <dt class="col-sm-5">Phone Numbers:</dt>
      <dd class="col-sm-7">{{selectedEducator.phoneNumbers.join(', ')}}</dd>
      <dt class="col-sm-5">Zip Code:</dt>
      <dd class="col-sm-7">{{selectedEducator.zipCode}}</dd>
      <dt class="col-sm-5">Fingerprint Card Number:</dt>
      <dd class="col-sm-7">{{selectedEducator.fingerprintCardNumber}}</dd>
      <dt class="col-sm-5">Other Zip Codes:</dt>
      <dd class="col-sm-7">{{selectedEducator.otherZipCodes.join(', ')}}</dd>
    </dl>
  </div>
</div>

<form #loginForm="ngForm">
  <div class="d-grid gap-2 mt-4">
    <button class="btn btn-primary btn-block" (click)="login()">Login</button>
    <div formLevelValidationErrors [for]="loginForm" [errorEmitter]="errorEmitter"></div>
  </div>
</form>
