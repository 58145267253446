import { Component, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthClient, EducatorVerificationStepOneCommand } from 'src/shared/services/api.service';
import { dateParser } from 'src/shared/services/mask-date-parser';
import { catchValidationErrors } from 'src/shared/validation/validator-utilities';

@Component({
  selector: 'edportal-educator-verification-step-one',
  templateUrl: './educator-verification-step-one.component.html',
  styles: ``
})
export class EducatorVerificationStepOneComponent {
  @ViewChild('stepOneForm') form!: NgForm;
  stepOneCommand: EducatorVerificationStepOneCommand = {}
  errorEmitter = new EventEmitter<any>();

  dateParser = dateParser;

  constructor(private authClient: AuthClient, private router: Router) {

  }

  onContinue() {
    this.authClient.completeStepOneVerification(this.stepOneCommand)
      .pipe(catchValidationErrors(this.form, this.errorEmitter))
      .subscribe(complete => {
        if (complete) {
          this.router.navigate(['/verify/two'], { replaceUrl: true });
        } else {
          this.router.navigate(['/verify/failed'], { replaceUrl: true });
        }
      })
  }
}
