import { Component, Input } from '@angular/core';
import { PublicEducatorEndorsementDto } from 'src/shared/services/api.service';

@Component({
  selector: 'edportal-educator-endorsement-detail',
  templateUrl: './educator-endorsement-detail.component.html',
  styles: ``
})
export class EducatorEndorsementDetailComponent {
  @Input() endorsements: PublicEducatorEndorsementDto[] = [];
}
