import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { TokenKey } from 'src/shared/constants';
import { LocalStorageService } from '../../shared/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  constructor(private localStorageService: LocalStorageService) {

  }

  setToken(token: string) {
    if (token) {
      this.localStorageService.set(TokenKey, token);
    }
  }

  decodeToken(): { [key: string]: string } | undefined {
    let token = this.localStorageService.get(TokenKey);

    if (token) {
      return jwtDecode(token);
    }

    return;
  }

  getToken(): string | null {
    return this.localStorageService.get(TokenKey);
  }

  removeToken(): void {
    this.localStorageService.remove(TokenKey);
  }

  isTokenExpired(): boolean {
    const token = this.decodeToken();

    if (!token) {
      return false;
    }

    const expiryTime: number = parseInt(token.exp);

    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
    } else {
      return false;
    }
  }

  isTokenRenewable(): boolean {
    const token = this.decodeToken();

    if (!token) {
      return false;
    }

    const refreshExpiry: number = parseInt(token.RefreshExpiry);

    if (refreshExpiry) {
      return ((1000 * refreshExpiry) - (new Date()).getTime()) > 5000;
    } else {
      return false;
    }
  }

  get userId(): number | undefined {
    const token = this.decodeToken();

    if (!token?.sub) {
      return undefined;
    }

    return parseInt(token.sub);
  }

  get email(): string | undefined {
    const token = this.decodeToken();

    if (!token?.email) {
      return undefined;
    }

    return token.email;
  }

  get authMethod() {
    const token = this.decodeToken();

    if (!token?.authmethod) {
      return undefined;
    }

    return token.authmethod;
  }
}
