import { NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { camelCase } from "lodash";
import { EventEmitter } from '@angular/core';

export function catchValidationErrors(form: NgForm | undefined, validationErrorsEvent: EventEmitter<any>) {
  return function <T>(source: Observable<T>): Observable<T> {
    return new Observable(observer => {
      source.subscribe({
        next(value) {
          observer.next(value);
        },
        error(error: any) {
          if (error.status === 422) {
            const errors = JSON.parse(error.response);
            const modelErrors = [];
            const allErrors = [];
            for (const key of Object.keys(errors)) {
              const control = form?.controls[camelCase(key)];
              control?.setErrors(errors[key]);
              if (control) {
                control.setErrors(errors[key]);
                allErrors.push(errors[key]);
              } else {
                modelErrors.push(errors[key]);
              }
            }
            validationErrorsEvent.emit({modelErrors, allErrors});
          }

          observer.error(error);
        },
        complete() {
          observer.complete();
        }
      })
    })
  }
}
