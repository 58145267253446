import { Component, Input } from '@angular/core';
import { ConfigService } from 'src/shared/services/config.service';

@Component({
  selector: 'edportal-issue-collector',
  template: `<a class="nav-link" href="#" (click)="openIssueCollector($event)">Report Error</a>`
})
export class IssueCollectorComponent {
  @Input() traceId: string|undefined;
  @Input() errorId: string|undefined;
  constructor(private configService: ConfigService) {

  }

  ngOnInit() {
    this.loadScript(this.configService.config.jiraIssueCollectorUrl);
    this.setAtlJqPageProps();
  }

  setAtlJqPageProps() {
    const fieldValues: any = {
      summary: 'Summarize problem here.',
      description: 'Add details about the problem here, what you did, what happened, what you expected.',
      customfield_10124: 'Please add the EIN, if applicable.'
    };

    if (this.traceId) {
      fieldValues.description += `\n\nTrace ID: ${this.traceId}`;
    }

    if (this.errorId) {
      fieldValues.description += `\n\nError ID: ${this.errorId}`;
    }

    (window as any).ATL_JQ_PAGE_PROPS = {
      triggerFunction: function (showCollectorDialog: any) {
        (window as any).showCollectorDialog = showCollectorDialog;
      },
      fieldValues: fieldValues
    };
  }

  openIssueCollector(event: Event) {
    event.preventDefault();

    (window as any).showCollectorDialog();
  }

  loadScript(url: string) {
    const scriptExists = !!document.querySelector(`script[src="${url}"]`);

    if (!scriptExists) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      document.body.appendChild(script);
    }
  }
}
