import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'edportal-signin-redirect-callback',
  templateUrl: './signin-redirect-callback.component.html',
  styleUrls: ['./signin-redirect-callback.component.scss']
})
export class SigninRedirectCallbackComponent {
  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit() {
    this.authService.completeLogin().subscribe(() => {
      this.router.navigate(['/'], { replaceUrl: true });
    });
  }
}

