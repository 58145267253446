import { SimpleCodeDescriptionDto } from "./services/api.service";

export const TokenKey: string = 'LOCAL_TOKEN';
export const Production: string = 'Production';

export const AdeConnectSignIn: string = 'https://fs.azed.gov/adfs/ls/?wtrealm=https%3A%2F%2Fadeconnect.azed.gov%2F&wa=wsignin1.0&wreply=https%3A%2F%2Fadeconnect.azed.gov%2Fsignin-wsfed%3FADEConnSelectedEID%3D&wctx=CfDJ8DfvRDM_5jtKn07iRoSt2YPa_H_z15yS8zk8m9VEPr5MJd9BgDFPxP8YtsiQWiAVUwbqfjvV8M9GVVBy1ZyVURT2UEB7_m3Kz_vf1s2QyPKUsDIniAmaRoCvASOEafhyzGHsuCSoflhV87wDMASFlYl081sYpfllEHDq0oQ0kKFe7_p9bonJFs4II1ilp2D3pnrKMJpiQSGHoowie3PmPoS-p5Qc3iuU4sX_2YlLMpyVQeCgg2_CHEg1iOeN_dyZ0A&client-request-id=3edf5870-4664-461d-99c2-b19d902dc2c1&RedirectToIdentityProvider=AD+AUTHORITY';

export enum AuthenticationMethods {
  adeConnect = 'adeConnect',
  test = 'test'
}

export enum FingerprintCardValidityStatus {
  Valid = 'Valid',
  NotValid = 'Not Valid',
  Pending = 'Pending',
  NotOnFile = 'Not On File',
  Expired = 'Expired'
}

export enum FormEvents {
  Successful,
  Error,
  Cancelled
}

export const AzStateCode: string = 'AZ';

export enum ServiceStatus {
  Received = 'Received',
  Cancelled = 'Cancelled',
  AdministrativeIncomplete = 'Administrative Incomplete',
  SubstantiveIncomplete = 'Substantive Incomplete',
  SubstantiveReview = 'Substantive Review',
  Denied = 'Denied',
  Appealed = 'Appealed',
  AdministrativeReview = 'Administrative Review',
  InvestigativeReview = 'Investigative Review',
  Qualified = 'Qualified',
  ReturnedIncomplete = 'Returned Incomplete',
  AdministrativelyWithdrawn = 'Administratively Withdrawn',
  Issued = 'Issued'
}

export enum CertificateStatusCodes {
  Valid = 'VAL',
  Expired = 'EXP',
  Lapsed = 'LAP',
  SuspendedNonDisciplinary = 'SUSPND',
  Revoked = 'REV',
  Surrendered = 'SUR',
  Suspended = 'SUSP',
  NotActive = 'NOTACTV'
}

export const UnitedStates: string = 'United States';
export const OutOfCountry: string = 'Out of Country';

export const DegreeCountryOptions: SimpleCodeDescriptionDto[] = [
  {
    id: 1,
    code: 'US',
    description: UnitedStates
  },
  {
    id: 2,
    code: 'OOC',
    description: OutOfCountry
  }
]


export enum TeachingEmploymentClassification {
  Contract = 'Contract',
  Substitute = 'Substitute',
  AtWill = 'At Will',
}

export enum NonTeachingEmploymentClassification {
  Administrative = 'Administrative',
  ProfessionalNonTeaching = 'Professional Non-Teaching',
  OtherLeadership = 'Other Leadership',
}

export enum EmploymentStatus {
  FullTime = 'Full Time',
  Parttime = 'Part Time'
}
