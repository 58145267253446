import { Component } from '@angular/core';
import { PublicPortalClient } from 'src/shared/services/api.service';
import { FileService } from 'src/shared/services/file.service';

@Component({
  selector: 'edportal-footer',
  templateUrl: './footer.component.html',
  styles: ``
})
export class FooterComponent {

  constructor(private fileService: FileService, private publicPortalClient: PublicPortalClient) { }

  onGlossaryOfTerms() {
    this.publicPortalClient.downloadGlossaryOfTerms().subscribe(fileResponse => {
      if (fileResponse?.data) {
        this.fileService.renameAndDownloadPdfFile(fileResponse, 'AZED_Public_Educator_Lookup_Glossary.pdf');
      }
    });
  }
}
