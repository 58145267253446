import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'edportal-tos',
  templateUrl: './tos.component.html',
  styles: ``
})
export class TosComponent {
  constructor(private router: Router) {
  }

  agree() {
    this.router.navigateByUrl('/launch/account');
  }
}
