import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable, finalize, map } from 'rxjs';
import { PublicEducatorCertificateDetailDto, PublicPortalClient } from 'src/shared/services/api.service';

@Component({
  selector: 'edportal-educator-detail',
  templateUrl: './educator-detail.component.html',
  styles: ``
})
export class EducatorDetailComponent {

  constructor(private route: ActivatedRoute, private publicPortalClient: PublicPortalClient, private router: Router) { }
  personId$!: Observable<number>;

  personId!: number;
  educator!: PublicEducatorCertificateDetailDto;
  dataLoaded: boolean = false;

  ngOnInit() {
    this.personId$ = this.route.paramMap.pipe(
      map((params: ParamMap) => {
        return parseInt(params.get('personId')!);
      }));

    this.personId$.subscribe(x => {
      this.getEducatorCertificateDetail(x);
    });
  }

  getEducatorCertificateDetail(personId: number) {
    this.publicPortalClient.getEducatorCertificateDetail(personId)
      .pipe(
        finalize(() => this.dataLoaded = true)
      )
      .subscribe(x => {
        this.educator = x;
      });
  }

  navigateSearch() {
    this.router.navigate(['public']);
  }
}
