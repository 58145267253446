import { Component } from '@angular/core';
import { AuthService } from 'src/auth/services/auth.service';
import { JwtService } from 'src/auth/services/jwt.service';
import { ConfigService } from 'src/shared/services/config.service';

@Component({
  selector: 'edportal-splash-layout',
  templateUrl: './splash-layout.component.html',
  styleUrls: ['./splash-layout.component.scss']
})
export class SplashLayoutComponent {
  isLoggedIn!: boolean;
  loggedInEmail!: string;
  environment?: string;

  constructor(private configService: ConfigService, private authService: AuthService, private jwtService: JwtService) {
    this.environment = this.configService.config.environment;
  }

  ngOnInit() {
    this.authService.isLoggedIn().subscribe(x => this.isLoggedIn = x);
    this.loggedInEmail = this.jwtService.email as string;
  }

  onLogout(event: Event): void {
    event.preventDefault();

    this.authService.logout();
  }
}
