
import { Component, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AzStateCode, FormEvents } from 'src/shared/constants';
import { countryOptions, SelectListOption } from 'src/shared/models/SelectListOptions';
import { AddOrUpdatePersonsAddressCommand, PersonAddressDto, ProfileClient, StateDto } from 'src/shared/services/api.service';
import { catchValidationErrors } from 'src/shared/validation/validator-utilities';

@Component({
  selector: 'edportal-educator-contact-address-form',
  templateUrl: './educator-contact-address-form.component.html',
  styleUrls: ['./educator-contact-address-form.component.scss']
})
export class EducatorContactAddressFormComponent {
  @Input() address!: PersonAddressDto;
  @Input() states!: StateDto[];
  @Output() addressChanged = new EventEmitter<FormEvents>();

  @ViewChild('addOrUpdateAddressForm') form!: NgForm;
  errorEmitter = new EventEmitter<any>();

  countryOptions: SelectListOption<boolean>[] = countryOptions;
  addOrUpdatePersonsAddressCommand!: AddOrUpdatePersonsAddressCommand;

  constructor(
    private renderer: Renderer2, private profileClient: ProfileClient) { }

  ngOnInit() {
    this.addOrUpdatePersonsAddressCommand = {
      personAddressId: this.address?.personAddressId,
      line1: this.address?.line1 ?? '',
      line2: this.address?.line2,
      outOfCountry: this.address?.isOutOfCountry ?? false,
      city: this.address?.city,
      stateCodeId: this.address?.stateCodeId ?? this.states.find(x => x.stateCode == AzStateCode)?.stateCodeId,
      zipCode: this.address?.zipCode ?? '',
      zipPlusFourOption: this.address?.zipPlusFourCode,
      cityStateProvince: this.address?.cityStateProvince
    }
  }

  ngAfterViewInit() {
    this.renderer.selectRootElement('#line1').focus();
  }

  cancelAddAddress() {
    this.addressChanged.emit(FormEvents.Cancelled);
  }

  onSubmitAddress() {
    const isAdd = !this.addOrUpdatePersonsAddressCommand?.personAddressId;

    const observable = isAdd
      ? this.profileClient.addPersonsAddress(this.addOrUpdatePersonsAddressCommand)
      : this.profileClient.updatePersonsAddress(this.addOrUpdatePersonsAddressCommand);

    observable
      .pipe(catchValidationErrors(this.form, this.errorEmitter))
      .subscribe({
        next: () => {
          this.addressChanged.emit(FormEvents.Successful);
        },
        error: () => {
          this.addressChanged.emit(FormEvents.Error);
        }
      });
  }
}
