<form #addOrUpdateAddressForm="ngForm" autocomplete="off">
    <div class="col-sm">
      <div class="form-group">
        <label for="line1">Line 1</label>
        <input #line1="ngModel" class="form-control" type="text" id="line1" name="line1" required
          [(ngModel)]="addOrUpdatePersonsAddressCommand.line1" placeholder="Enter an address...">
        <div validationErrors [for]="line1.control" [errorEmitter]="errorEmitter"></div>
      </div>
    </div>

    <div class="col-sm">
      <div class="form-group">
        <label for="line2">Line 2</label>
        <input #line2="ngModel" class="form-control" type="text" id="line2" name="line2"
          [(ngModel)]="addOrUpdatePersonsAddressCommand.line2">
      </div>
    </div>

    <div class="col-sm">
      <div class="form-group">
        <label for="country">Country</label>
        <ng-select #outOfCountry="ngModel" id="country" name="country" labelForId="country" [items]="countryOptions" [closeOnSelect]="true"
          [multiple]="false" [bindValue]="'value'" [bindLabel]="'text'"
          [(ngModel)]="addOrUpdatePersonsAddressCommand.outOfCountry">
        </ng-select>
        <div validationErrors [for]="outOfCountry.control" [errorEmitter]="errorEmitter"></div>
      </div>
    </div>

    <div class="col-sm" *ngIf="addOrUpdatePersonsAddressCommand.outOfCountry == false">
      <div class="form-group">
        <label for="city">City</label>
        <input #city="ngModel" class="form-control" type="text" id="city" name="city"
          [(ngModel)]="addOrUpdatePersonsAddressCommand.city" [required]="addOrUpdatePersonsAddressCommand.outOfCountry == false"
          placeholder="Enter a city...">
        <div validationErrors [for]="city.control" [errorEmitter]="errorEmitter"></div>
      </div>
    </div>

    <div class="col-sm" *ngIf="addOrUpdatePersonsAddressCommand.outOfCountry == false">
      <div class="form-group">
        <label for="state-select">State</label>
        <ng-select #state="ngModel" id="state-select" name="state-select" labelForId="state-select" [items]="states" [required]="addOrUpdatePersonsAddressCommand.outOfCountry == false"
          [closeOnSelect]="true" [multiple]="false" placeholder="Select a state..." [bindValue]="'stateCodeId'" [bindLabel]="'description'"
          [(ngModel)]="addOrUpdatePersonsAddressCommand.stateCodeId">
        </ng-select>
          <div validationErrors [for]="state.control" [errorEmitter]="errorEmitter"></div>
      </div>
    </div>

    <div class="col-sm" *ngIf="addOrUpdatePersonsAddressCommand.outOfCountry == true">
      <div class="form-group">
        <label for="cityStateProvince">City/State/Province</label>
        <input #cityStateProvince="ngModel" class="form-control" type="text" id="cityStateProvince" [required]="addOrUpdatePersonsAddressCommand.outOfCountry == true"
          name="cityStateProvince" [(ngModel)]="addOrUpdatePersonsAddressCommand.cityStateProvince" placeholder="Enter a city/state/province...">
          <div validationErrors [for]="cityStateProvince.control" [errorEmitter]="errorEmitter"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg form-group">
        <label for="zipCode">Zip Code</label>
        <input #zipCode="ngModel" class="form-control" type="text" id="zipCode" name="zipCode"
          [(ngModel)]="addOrUpdatePersonsAddressCommand.zipCode" required placeholder="Enter a zip code...">
          <div validationErrors [for]="zipCode.control" [errorEmitter]="errorEmitter"></div>
      </div>

      <div class="col-lg form-group" *ngIf="addOrUpdatePersonsAddressCommand.outOfCountry == false">
        <label for="zipPlusFourCode">Zip Code Plus 4</label>
        <input #zipPlusFourOption="ngModel" class="form-control" type="text" id="zipPlusFourCode"
          name="zipPlusFourCode" [(ngModel)]="addOrUpdatePersonsAddressCommand.zipPlusFourOption" numericOnly maxlength="4">
      </div>
    </div>

  <div class="row text-end mt-3">
    <div class="col">
      <button class="btn btn-sm btn-outline-secondary me-2" (click)="cancelAddAddress()" type="button">Cancel</button>
      <button class="btn btn-sm btn-primary" (click)="onSubmitAddress()" type="submit">Save</button>
    </div>
  </div>
</form>
