import { Component, Input } from '@angular/core';
import { PublicEducatorApprovedAreaDto } from 'src/shared/services/api.service';

@Component({
  selector: 'edportal-educator-approved-area-detail',
  templateUrl: './educator-approved-area-detail.component.html',
  styles: ``
})
export class EducatorApprovedAreaDetailComponent {
  @Input() approvedAreas: PublicEducatorApprovedAreaDto[] = [];
}
