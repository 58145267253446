import { Injectable, Provider } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, catchError, filter, finalize, switchMap, take, tap, throwError } from 'rxjs';
import { JwtService } from '../services/jwt.service';
import { Router } from '@angular/router';
import { TokenRefreshResult } from 'src/shared/services/api.service';
import { AuthService } from '../services/auth.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  // would REALLY like to generate these via nswag but can't crack the code at the moment.
  private anonymousRoutes = [
    '/api/Auth/ExchangeIdpToken',
    '/api/Auth/CreateNonProdUserToken',
    '/api/Auth/KeepAlive',
    '/api/Auth/GetEducatorsToImpersonate',
    '/api/Auth/Logout',
    '/api/Auth/RefreshToken',
    '/api/Auth/GetADEConnectRegistrationLink',
    '/api/Config/ClientConfig',
    '/api/PublicPortal/SearchEducator',
    '/api/PublicPortal/GetEducatorPositions',
    '/api/PublicPortal/GetTeachingGrades',
    '/api/PublicPortal/GetEducatorCertificateDetail',
    '/api/PublicPortal/DownloadGlossaryOfTerms'
  ];

  constructor(private jwtService: JwtService, private router: Router, private authService: AuthService) {

  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.jwtService.getToken();
    const isAnonymousRoute = this.anonymousRoutes.some(route => request.url === route);

    if (isAnonymousRoute || (!!token && !this.jwtService.isTokenExpired())) {
      request = this.addTokenHeader(request, this.jwtService.getToken()!);

      return next.handle(request).pipe(catchError(error => {
        if (error.headers.has('clear-token')) {
          this.authService.logout();
          return EMPTY;
        }
        return throwError(() => error);
      }));
    }

    return this.handleExpiredToken(request, next);
  }

  private handleExpiredToken(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((result: TokenRefreshResult) => {
          if (result.wasRefreshed) {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(result.token);
            return next.handle(this.addTokenHeader(request, result.token));
          } else {
            this.authService.logout();
            return EMPTY;
          }
        }),
        catchError((error) => {
          // On error, logout and clear the queue
          this.authService.logout();
          return EMPTY;
        }),
        finalize(() => {
          this.isRefreshing = false; // Reset the refreshing flag once the sequence completes or errors out
        }));

    } else {
      return this.queueRequest(request, next);
    }
  }

  private queueRequest(request: HttpRequest<any>, next: HttpHandler) {
    return this.refreshTokenSubject.pipe(
      filter(token => token != null),
      take(1),
      switchMap(jwt => {
        return next.handle(this.addTokenHeader(request, jwt));
      }));
  }
}

export const TokenInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true
}
