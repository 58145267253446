import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PublicEducatorDto, SortDirection, SortField } from 'src/shared/services/api.service';

@Component({
  selector: 'edportal-public-search-results',
  templateUrl: './public-search-results.component.html',
  styles: ``
})
export class PublicSearchResultsComponent {
  constructor(private router: Router) { }

  @Input() educators: PublicEducatorDto[] = [];
  @Input() loading: Boolean = false;
  @Input() initialLoad: boolean = true;
  @Input() fiscalYear!: string
  @Output() sortChanged = new EventEmitter<{ sortField: SortField, sortDirection: SortDirection }>();

  sortField = SortField;
  sortDirection = SortDirection;

  onSortChange(sortField: SortField, sortDirection: SortDirection) {
    this.sortChanged.emit({ sortField, sortDirection });
  }

  navigateEducatorDetail(personId: number) {
    this.router.navigate([`public/educator-detail/${personId}`]);
  }

  get noResults() {
    return !this.initialLoad && !this.educators.length && !this.loading;
  }

  stopPropagation(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }
  }
}
