<div class="form-group">
  <div class="row align-items-center">
    <div class="col-lg d-flex">
      <h3 class="h5 fw-normal">Phone Number</h3>
      <span class="d-md-none">
        <i class="add-circle-icon ms-1 text-primary" (click)="enableAddPhone()"></i>
      </span>
    </div>
    <div class="col-lg text-end d-none d-md-block">
      <div>
        <button class="btn btn-primary" (click)="enableAddPhone()">Add</button>
      </div>
    </div>
  </div>
  <ul class="list-group mt-3">
    @if (!dataLoaded) {
      <li>
        <ngx-skeleton-loader *ngIf="!dataLoaded" count="1" [theme]="{height: '5rem'}"></ngx-skeleton-loader>
      </li>
    }
    @else if (!addPhoneMode && dataLoaded) {
      <li class="list-group-item" *ngIf="phoneNumbers.length === 0 && !addPhoneMode">
        <p class="h6 fw-normal m-1 py-3">
          There are no phone numbers on file for this educator
        </p>
      </li>
      <li class="list-group-item clickable" *ngFor="let number of phoneNumbers" (click)="loadEditForm(number)"
        [ngClass]="phoneNumberItemClasses(number)">
        <div *ngIf="!!number" class="d-flex justify-content-between my-1">
          <div>
            <span class="h6 fw-normal me-2">{{number.phoneNumber | phoneNumber}}</span>
            <span class="badge-md badge-info me-2">{{number.description}}</span>
            <span class="badge-md badge-light me-2" *ngIf="number.isMobile == true">Mobile</span>
          </div>
          <div *ngIf="number.personPhoneNumberId != this.selectedPhoneNumber?.personPhoneNumberId">
            <i certTooltip="Edit" class="edit-icon-lg"></i>
          </div>
        </div>
      </li>
    }
    @else if (addPhoneMode && dataLoaded) {
      <li class="list-group-item">
        <edportal-educator-contact-phone-form #phoneForm [phoneNumber]="selectedPhoneNumber"
          [phoneOptions]="phoneOptions" (phoneChanged)="handleFormEvent($event)"></edportal-educator-contact-phone-form>
      </li>
    }
  </ul>
</div>
