@if(pendingApp && !pendingApp.submitted)
{
  <div [@slideDown] class="sticky-top alert alert-secondary d-flex justify-content-between align-items-center" role="alert">
    <div>
      <div class="lead fs-5">You've started an application. Click on the icon to the right to continue.</div>
      <div class="fs-6">Do you want to cancel this application? <a href="#" (click)="cancel($event)">Cancel Application</a></div>
    </div>
    <div class="position-relative fs-2">
      <a href="#" (click)="goToSummary($event)" [edPortalTooltip]="serviceItemCountExplanation">
        <i class="doc-icon text-info"></i>
        <span id="item-count"
          class="position-absolute top-100 start-100 translate-middle badge rounded-pill bg-info fs-6">
          {{serviceItemCount}}
          <span class="visually-hidden">service items on application</span>
        </span>
      </a>
    </div>
  </div>
}
@else if(pendingApp && pendingApp.submitted) {
  <div [@slideDown] class="sticky-top alert alert-success d-flex justify-content-between align-items-center" role="alert">
    <div>
      <div class="lead fs-5">You're application has been dispatched to the department of education.</div>
      <div class="fs-6">
        You won't be able to submit another application until the current one is delivered.
        If you see this status for an extended period of time, please contact the department of education for support.
      </div>
    </div>
    <div class="position-relative fs-2">
        <i class="bi bi-send-check text-success"></i>
    </div>
  </div>
}
