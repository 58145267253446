import { Directive, ElementRef, Input, OnInit, SimpleChanges } from '@angular/core';
import { Tooltip } from 'bootstrap';

@Directive({
  selector: '[edPortalTooltip]'
})
export class TooltipDirective implements OnInit {
  private tooltipInstance: Tooltip | null = null;

  @Input('edPortalTooltip') tooltipText!: string;
  @Input() enableTooltip: boolean = true;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.initializeTooltip();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.enableTooltip) {
      this.initializeTooltip();
    }
  }

  private initializeTooltip() {
    if (this.tooltipInstance) {
      this.tooltipInstance.dispose();
      this.tooltipInstance = null;
    }

    if (this.enableTooltip) {
      this.tooltipInstance = new Tooltip(this.el.nativeElement, {
        title: this.tooltipText
      });
    }
  }

  ngOnDestroy() {
    if (this.tooltipInstance) {
      this.tooltipInstance.dispose();
      this.tooltipInstance = null;
    }
  }
}
