import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/auth/services/auth.service';
import { Production } from 'src/shared/constants';
import { AuthClient } from 'src/shared/services/api.service';
import { ConfigService } from 'src/shared/services/config.service';

@Component({
  selector: 'edportal-account-registration',
  templateUrl: './account-registration.component.html',
  styles: ``
})
export class AccountRegistrationComponent {
  registrationLink!: string;

  constructor(
    private authService: AuthService, private configService: ConfigService,
    private router: Router, private authClient: AuthClient) {
  }

  ngOnInit() {
    this.getRegistrationUrl();
  }

  getRegistrationUrl() {
    this.authClient.getADEConnectRegistrationLink().subscribe(x => {
      this.registrationLink = x;
    })
  }

  login() {
    if (this.configService.config.environment !== Production && this.configService.config.testModeAllowed) {
      this.router.navigateByUrl('/launch/selector');
    } else {
      this.authService.login();
    }
  }
}
