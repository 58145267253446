<div class="text-center mb-3">
  <h1 class="h4">Identity Verification</h1>
  <div class="lead">
    Thanks for the input. We need to collect a little more to confirm your identity.
  </div>
</div>
<form #stepTwoForm="ngForm" autocomplete="off">
  <div class="form-group">
    <label class="form-label" for="lastName">Last Name</label>
    <input #lastName="ngModel" [(ngModel)]="stepTwoCommand.lastName" class="form-control" name="lastName" id="lastName"
      type="text" />
    <div validationErrors [for]="lastName.control" [errorEmitter]="errorEmitter"></div>
  </div>
  <div class="form-group">
    <label class="form-label" for="phoneNumber">Phone Number</label>
    <input #phone="ngModel" [(ngModel)]="stepTwoCommand.phoneNumber" class="form-control" name="phoneNumber"
      id="phoneNumber" type="text" mask="(000) 000-0000" />
    <div validationErrors [for]="phone.control" [errorEmitter]="errorEmitter"></div>
  </div>
  <div class="form-group">
    <label class="form-label" for="zipCode">Zip Code</label>
    <input #zipCode="ngModel" [(ngModel)]="stepTwoCommand.zipCode" class="form-control" name="zipCode" id="zipCode"
      type="text" mask="00000" />
    <div validationErrors [for]="zipCode.control" [errorEmitter]="errorEmitter"></div>
  </div>
</form>
<div class="d-grid mt-4">
  <button class="btn btn-primary mx-2" (click)="onContinue()">Continue</button>
</div>
