import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SplashLayoutComponent } from './splash-layout/splash-layout.component';
import { TosComponent } from './tos/tos.component';
import { AuthModeSelectorComponent } from './auth-mode-selector/auth-mode-selector.component';
import { EducatorSelectorComponent } from './educator-selector/educator-selector.component';
import { EducatorVerificationStepOneComponent } from './educator-verification-step-one/educator-verification-step-one.component';
import { EducatorVerificationResultComponent } from './educator-verification-result/educator-verification-result.component';
import { IsLoggedInGuard } from 'src/app/route-guards/is-logged-in.guard';
import { TestModeGuard } from 'src/app/route-guards/test-mode.guard';
import { resolveStepTwoRouteGuard } from './route-guards/resolve-step-two-route.guard';
import { EducatorVerificationStepTwoDpsComponent } from './educator-verification-step-two-dps/educator-verification-step-two-dps.component';
import { EducatorVerificationStepTwoContactComponent } from './educator-verification-step-two-contact/educator-verification-step-two-contact.component';
import { EducatorVerificationStalledComponent } from './educator-verification-stalled/educator-verification-stalled.component';
import { AccountRegistrationComponent } from './account-registration/account-registration.component';

export interface EducatorVerificationRouteData {
  verifyResult: boolean;
}

const moduleRoutes: Routes = [
  {
    path: '', component: SplashLayoutComponent,
    children: [
      { path: '', component: TosComponent, title: 'ADE Terms of Service' },
      { path: 'account', component: AccountRegistrationComponent, title: 'ADE Connect Account' },
      { path: 'selector', component: AuthModeSelectorComponent, canActivate: [TestModeGuard], title: 'Login Selector' },
      { path: 'configure', component: EducatorSelectorComponent, canActivate: [TestModeGuard], title: 'Educator Selector' },
      { path: 'verify/one', component: EducatorVerificationStepOneComponent, canActivate: [IsLoggedInGuard], title: 'Educator Verification' },
      { path: 'verify/two', component: EducatorVerificationStalledComponent, canActivate: [resolveStepTwoRouteGuard], title: 'Educator Verification' },
      { path: 'verify/two-dps', component: EducatorVerificationStepTwoDpsComponent, canActivate: [IsLoggedInGuard], title: 'Educator Verification' },
      { path: 'verify/two-contact', component: EducatorVerificationStepTwoContactComponent, canActivate: [IsLoggedInGuard], title: 'Educator Verification' },
      { path: 'verify/stalled', component: EducatorVerificationStalledComponent, canActivate: [IsLoggedInGuard], title: 'Educator Verification' },
      { path: 'verify/failed', component: EducatorVerificationResultComponent, canActivate: [IsLoggedInGuard], title: 'Educator Verification', data: { 'verifyResult': false } as EducatorVerificationRouteData},
      { path: 'verify/success', component: EducatorVerificationResultComponent, canActivate: [IsLoggedInGuard], title: 'Educator Verification', data: { 'verifyResult': true } as EducatorVerificationRouteData }
    ]
  }
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(moduleRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class SplashRoutingModule { }
