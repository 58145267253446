<body class="bg-gray-100 d-flex flex-column">
  <div class="container py-4 px-3 mx-auto flex-grow-1">
    <header class="row justify-content-md-center text-center py-5">
      <div class="col-lg-6">
        <img src="/assets/azde-horizontal-logo-new.png" alt="Arizona Department of Education" class="img-fluid" />
        <h1 class="display-4">Public Educator Lookup</h1>
      </div>
    </header>
    <main>
      <form #publicSearchForm="ngForm" class="row g-3">
        <div class="col-md-4">
          <div class="form-floating">
            <input [(ngModel)]="publicSearchEducator.lastName" type="text" class="form-control form-control-lg"
              id="lastName" name="lastName" placeholder="Last Name">
            <label for="lastName">Last Name</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-floating">
            <input [(ngModel)]="publicSearchEducator.firstName" type="text" class="form-control form-control-lg"
              id="firstName" name="firstName" type="text" class="form-control form-control-lg"
              placeholder="First Name">
            <label for="firstName">First Name</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-floating">
            <input [(ngModel)]="publicSearchEducator.ein" id="ein" name="ein" type="text" numericOnly
              class="form-control form-control-lg" id="ein" placeholder="Educator Identification Number (EIN)">
            <label for="ein">Educator Identification Number (EIN)</label>
          </div>
        </div>
        <div class="col-md-6">
          <ng-select #educatorPosition="ngModel" id="educatorPosition" name="educatorPosition" [closeOnSelect]="true"
            [multiple]="false" [(ngModel)]="publicSearchEducator.positionId" placeholder="Select Educator Position"
            aria-label="Educator Position">
            <ng-option *ngFor="let position of educatorPositions"
              [value]="position.positionId">{{position.description}}</ng-option>
          </ng-select>
        </div>
        <div class="col-md-6">
          <ng-select #teachingGrade="ngModel" id="teachingGrade" name="teachingGrade" [closeOnSelect]="true"
            [multiple]="false" [(ngModel)]="publicSearchEducator.teachingGrade" placeholder="Select Teaching Grade"
            aria-label="Teaching Grade">
            <ng-option *ngFor="let grade of teachingGrades" [value]="grade.codeValue">{{grade.codeValue}} -
              {{grade.description}}</ng-option>
          </ng-select>
        </div>
        <div class="d-grid text-center gap-2 d-lg-block">
          <button type="submit" class="btn btn-lg btn-primary me-1 px-lg-5" (click)="onSearch()">Search</button>
          <button type="reset" class="btn btn-lg btn-outline-primary ms-1 px-lg-5" (click)="onReset()">Reset</button>
        </div>
      </form>
      <div *ngIf="!initialLoad">
        <edportal-public-search-results [educators]="educators" [loading]="loading" [initialLoad]="initialLoad"
          (sortChanged)="onSortChanged($event)" (pageChangedPrev)="onPrevPage()" [fiscalYear]="fiscalYear"
          (pageChangedNext)="onNextPage()"></edportal-public-search-results>
        <div *ngIf="educators.length" class="btn-group d-flex justify-content-center pt-2" role="group">
          <div class="btn-group" role="group">
            <button type="button" class="btn btn-outline-primary border-end-0" (click)="onPrevPage()"
              [disabled]="publicSearchEducator.pageNumber <= 1" aria-label="Previous"><i
                class="bi bi-caret-left-fill me-1 fs-sm"></i>Prev</button>
          </div>
          <div class="btn-group" role="group">
            <button type="button" class="btn btn-outline-primary" (click)="onNextPage()"
              [disabled]="publicSearchEducator.pageNumber >= maxPageSize || loading">Next<i
                class="bi bi-caret-right-fill ms-1 fs-sm"></i></button>
          </div>
        </div>
      </div>
    </main>
  </div>
  <footer class="mt-auto">
    <edportal-footer/>
  </footer>
</body>
