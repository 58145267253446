export interface SelectListOption<T> {
  value: T,
  text: string
}

export const countryOptions: SelectListOption<boolean>[] = [
  { value: false,  text: 'United States' },
  { value: true,  text: 'Out of Country' },
]

export const yesNoOptions: SelectListOption<boolean>[] = [
  { value: true,  text: 'Yes' },
  { value: false,  text: 'No' },
]
