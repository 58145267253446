import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/auth/services/auth.service';

@Component({
  selector: 'edportal-auth-mode-selector',
  templateUrl: './auth-mode-selector.component.html',
  styles: ``
})
export class AuthModeSelectorComponent {

  constructor(private authService: AuthService, private router: Router) {

  }

  standardLogin() {
    this.authService.login();
  }

  testUserLogin() {
    this.router.navigateByUrl('launch/configure')
  }
}
