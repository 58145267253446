import { Component, Input } from '@angular/core';
import { ApplicationServiceSummaryItem } from '../services/api.service';
import { ServiceStatus } from '../constants';

@Component({
  selector: 'edportal-progress-stepper',
  templateUrl: './progress-stepper.component.html',
  styleUrl: './progress-stepper.component.scss'
})
export class ProgressStepperComponent {
  @Input()
  service?: ApplicationServiceSummaryItem;

  getButtonClass(service?: ApplicationServiceSummaryItem, phaseNumber?: number): string {
    if (!service || !phaseNumber) {
      return 'empty-pill';
    }

    const currentPhase = service.phase;

    if (phaseNumber < currentPhase) {
      return 'btn-success';
    }

    if (currentPhase == 4 && service.hasAllowableDeficiencies) {
      return 'btn-warning';
    }

    if (phaseNumber === currentPhase) {
      return this.getCurrentPhaseButtonClass(service.status);
    }

    return 'empty-pill';
  }

  private getCurrentPhaseButtonClass(status: string): string {
    switch (status) {
      case ServiceStatus.Received:
      case ServiceStatus.AdministrativeReview:
      case ServiceStatus.SubstantiveReview:
      case ServiceStatus.InvestigativeReview:
      case ServiceStatus.Qualified:
        return 'btn-info';

      case ServiceStatus.ReturnedIncomplete:
      case ServiceStatus.AdministrativeIncomplete:
      case ServiceStatus.AdministrativelyWithdrawn:
      case ServiceStatus.SubstantiveIncomplete:
      case ServiceStatus.Denied:
      case ServiceStatus.Cancelled:
        return 'btn-danger';

      case ServiceStatus.Issued:
        return 'btn-success';

      default:
        return 'btn-secondary';
    }
  }

  private getCurrentPhaseIcon(status: string): string {
    switch (status) {
      case ServiceStatus.Received:
        return 'bi-envelope-paper-fill';

      case ServiceStatus.AdministrativeReview:
      case ServiceStatus.SubstantiveReview:
      case ServiceStatus.InvestigativeReview:
      case ServiceStatus.Qualified:
        return 'bi-hourglass-split';

      case ServiceStatus.ReturnedIncomplete:
      case ServiceStatus.AdministrativeIncomplete:
      case ServiceStatus.AdministrativelyWithdrawn:
      case ServiceStatus.SubstantiveIncomplete:
      case ServiceStatus.Denied:
      case ServiceStatus.Cancelled:
        return 'bi-x-lg';

      case ServiceStatus.Issued:
        return 'bi-check-lg';

      default:
        return 'bi-question-lg';
    }
  }

  getIcon(service?: ApplicationServiceSummaryItem, phaseNumber?: number): string {
    if (!service || !phaseNumber) {
      return 'bi-question-lg';
    }

    const currentPhase = service.phase;

    if (phaseNumber < currentPhase) {
      return 'bi-check-lg';
    }

    if (phaseNumber === currentPhase) {
      return this.getCurrentPhaseIcon(service.status);
    }

    return '';
  }

  getLeftPosition(index: number): string {
    const position = (index / 3) * 100;
    return position + '%';
  }

  getTooltip(service?: ApplicationServiceSummaryItem, phaseNumber?: number): string {
    if (!service || !phaseNumber) {
      return 'Pending';
    }

    const currentPhase = service.phase;

    if (phaseNumber < currentPhase) {
      switch (phaseNumber) {
        case 1:
          return 'Evaluation Started';
        case 2:
          return 'Administratively Complete';
        case 3:
          return 'Substantively Complete';
      }
    }

    else if (phaseNumber == currentPhase) {
      return service.phaseStatusDescription;
    }

    return '';
  }
}
