<footer class="footer bg-gray-200 p-3 mt-4 rounded">
  <div class="row">
    <div class="col-md">&copy; 2024 Arizona Department of Education</div>
    <div class="col-md text-start text-md-end">
      <a href="https://www.azed.gov/educator-certification/" class="pe-2 pe-md-0 ps-md-2 text-decoration-none"
        target="_blank" rel="noopener">Certification Home</a>
      <a role="button" aria-controls="glossary"
        class="pe-2 pe-md-0 ps-md-2 text-decoration-none" (click)="onGlossaryOfTerms()">Glossary of Terms</a>
      <a href="#disclaimer" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="disclaimer"
        class="pe-2 pe-md-0 ps-md-2 text-decoration-none">Disclaimer</a>
    </div>
  </div>
<edportal-disclaimer></edportal-disclaimer>
</footer>
