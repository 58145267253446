import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, finalize, tap } from 'rxjs';
import { CreatePendingAddApprovedAreaServiceItemCommand, CreatePendingAddCertificateServiceItemCommand, CreatePendingAddEndorsementServiceItemCommand, CreatePendingAddInstitutionalRecommendationServiceItemCommand, CreatePendingCopyCertificateServiceItemCommand, CreatePendingExtendCertificateServiceItemCommand, CreatePendingNameChangeServiceItemCommand, CreatePendingRemoveDeficiencyServiceItemCommand, CreatePendingRenewCertificateServiceItemCommand, DeletePendingServiceItemDto, PendingApplicationClient, PendingApplicationDto, PendingServiceItemDto, PendingToDoItemDto } from './api.service';
import { ToDoListService } from './todo-list.service';

@Injectable({
  providedIn: 'root'
})
export class PendingApplicationService {
  // Using behavior subjects here allows a consumer to always get the most recent emitted value.
  public pendingAppClearedSubject: BehaviorSubject<boolean>;
  public pendingAppCleared$: Observable<boolean>;

  private pendingApplicationSubject: BehaviorSubject<PendingApplicationDto | null | undefined>;
  private pendingApplication$: Observable<PendingApplicationDto>;

  private fetchInProgress: boolean = false;

  constructor(private pendingAppClient: PendingApplicationClient, private toDoListService: ToDoListService) {
    this.pendingAppClearedSubject = new BehaviorSubject<boolean>(false);
    this.pendingAppCleared$ = this.pendingAppClearedSubject.asObservable();

    this.pendingApplicationSubject = new BehaviorSubject<PendingApplicationDto | null | undefined>(undefined);
    this.pendingApplication$ = this.pendingApplicationSubject.asObservable() as BehaviorSubject<PendingApplicationDto>;
  }

  fetchApp(forceAppRefresh?: boolean): Observable<PendingApplicationDto> {
    // If we don't have a value in our cache and no request is in progress
    if (!this.pendingApplicationSubject.getValue() && !this.fetchInProgress || forceAppRefresh) {
      this.fetchInProgress = true; // Indicate that a fetch is in progress

      this.pendingAppClient.fetchPendingApplication().pipe(
        finalize(() => this.fetchInProgress = false) // Reset the flag once the fetch is complete
      ).subscribe(app => {
        this.pendingApplicationSubject.next(app);
      });
    }

    return this.pendingApplication$.pipe(
      filter((value): value is PendingApplicationDto => value !== undefined)
    );
  }

  refreshApp() {
    this.pendingAppClient.fetchPendingApplication().subscribe(app => {
      this.pendingApplicationSubject.next(app);
    });
  }

  createApp(): Observable<PendingApplicationDto> {
    return this.pendingAppClient.createPendingApplication().pipe(tap(x => {
      this.pendingApplicationSubject.next(x);
    }));
  }

  syncToDos() {
    // add todos that exist on the educator but not on the pending app.
  }

  skipToDo(toDo: PendingToDoItemDto) {
    // skip the to-do and update the app with a new version.
    this.pendingAppClient.skipApplicationToDo({
      pendingApplicationToDoItemId: toDo.pendingApplicationToDoItemId
    }).subscribe(x => {
      this.pendingApplicationSubject.next(x)
    });
  }

  cancel() {
    this.pendingAppClient.cancelPendingApplication().subscribe(() => {
      this.pendingApplicationSubject.next(null);
      this.toDoListService.requestRefreshToDoList();
    })
  }

  deleteServiceItem(pendingServiceItemId: number): Observable<DeletePendingServiceItemDto> {
    return this.pendingAppClient.deletePendingServiceItem({
      pendingServiceItemId
    }).pipe(tap(() => {
      let app = this.pendingApplicationSubject.getValue()!;
      app.pendingServiceItems = app?.pendingServiceItems.filter(s => s.pendingServiceItemId != pendingServiceItemId);

      this.pendingApplicationSubject.next(app);
    }));
  }

  addNewServiceItem(serviceItem: PendingServiceItemDto) {
    let app = this.pendingApplicationSubject.getValue()!;
    app.pendingServiceItems.push(serviceItem);

    this.pendingApplicationSubject.next(app);
  }

  createAddCertificateServiceItem(command: CreatePendingAddCertificateServiceItemCommand): Observable<PendingServiceItemDto> {
    return this.pendingAppClient.createPendingAddCertificateServiceItem(command)
      .pipe(tap(x => this.addNewServiceItem(x)));
  }

  createAddApprovedAreaServiceItem(command: CreatePendingAddApprovedAreaServiceItemCommand): Observable<PendingServiceItemDto> {
    return this.pendingAppClient.createPendingAddApprovedAreaServiceItem(command)
      .pipe(tap(x => this.addNewServiceItem(x)));
  }

  createRemoveDeficiencyServiceItem(command: CreatePendingRemoveDeficiencyServiceItemCommand): Observable<PendingServiceItemDto> {
    return this.pendingAppClient.createPendingRemoveDeficiencyServiceItem(command)
      .pipe(tap(x => this.addNewServiceItem(x)));
  }

  createRenewCertificateServiceItem(command: CreatePendingRenewCertificateServiceItemCommand): Observable<PendingServiceItemDto> {
    return this.pendingAppClient.createPendingRenewCertificateServiceItem(command)
      .pipe(tap(x => this.addNewServiceItem(x)));
  }

  createNameChangeServiceItem(command: CreatePendingNameChangeServiceItemCommand): Observable<PendingServiceItemDto> {
    return this.pendingAppClient.createPendingNameChangeServiceItem(command)
      .pipe(tap(x => this.addNewServiceItem(x)));
  }

  createCopyCertificateServiceItem(command: CreatePendingCopyCertificateServiceItemCommand): Observable<PendingServiceItemDto> {
    return this.pendingAppClient.createPendingCopyCertificateServiceItem(command)
      .pipe(tap(x => this.addNewServiceItem(x)));
  }

  createInstitutionalRecommendationServiceItem(command: CreatePendingAddInstitutionalRecommendationServiceItemCommand): Observable<any> {
    return this.pendingAppClient.createPendingAddInstitutionalRecommendationServiceItem(command);
  }

  createAddEndorsementServiceItem(command: CreatePendingAddEndorsementServiceItemCommand): Observable<PendingServiceItemDto> {
    return this.pendingAppClient.createPendingAddEndorsementServiceItem(command)
      .pipe(tap(x => this.addNewServiceItem(x)));
  }

  createExtendCertificateServiceItem(command: CreatePendingExtendCertificateServiceItemCommand): Observable<PendingServiceItemDto> {
    return this.pendingAppClient.createPendingExtendCertificateServiceItem(command)
      .pipe(tap(x => this.addNewServiceItem(x)));
  }
}
