<section class="mt-4 card card-body bg-white border-0 shadow-sm">
  <h3 class="h4">
    {{certificate.description}}
    @if (certificate.restrictedApprovedArea) {
      <span class="ms-2">(Subject Area - {{certificate.restrictedApprovedArea}})</span>
    }
  </h3>
  <div class="row">
    <div class="col">
      <span class="small">Issue Date</span>
      <p class="lead">{{certificate.startDate | date : 'MM/yyyy'}}</p>
    </div>
    <div class="col">
      <span class="small">Expiration Date</span>
      <p class="lead">{{certificate.expirationDate | date : 'MM/yyyy'}}</p>
    </div>
    <div class="col">
      <span class="small">Status</span>
      <ng-container [ngSwitch]="certificate.statusCode.code">
        <p *ngSwitchCase="certificateStatusCodes.Valid" class="lead"><span
            class="badge rounded-pill text-bg-success">Valid</span></p>
        <p *ngSwitchCase="certificateStatusCodes.Expired" class="lead"><span
            class="badge rounded-pill text-bg-danger">Expired</span></p>
        <p *ngSwitchCase="certificateStatusCodes.Lapsed" class="lead"><span
            class="badge rounded-pill text-bg-warning">Lapsed</span></p>
        <p *ngSwitchCase="certificateStatusCodes.SuspendedNonDisciplinary" class="lead"><span
            class="badge rounded-pill text-bg-info">Suspended (Non-Disciplinary)</span></p>
        <p *ngSwitchCase="certificateStatusCodes.Revoked" class="lead"><span
            class="badge rounded-pill text-bg-dark">Revoked</span></p>
        <p *ngSwitchCase="certificateStatusCodes.Surrendered" class="lead"><span
            class="badge rounded-pill text-bg-primary">Surrendered</span></p>
        <p *ngSwitchCase="certificateStatusCodes.Suspended" class="lead"><span
            class="badge rounded-pill text-bg-danger">Suspended</span></p>
        <p *ngSwitchCase="certificateStatusCodes.NotActive" class="lead"><span
            class="badge rounded-pill text-bg-secondary">Not Active</span></p>
        <span *ngSwitchDefault class="badge badge-lg badge-danger me-2">Unknown Status</span>
      </ng-container>
    </div>
  </div>
  <div *ngIf="certificate.allowableDeficiencies.length" class="row">
    <div class="table-responsive mt-1">
      <table class="table">
        <thead>
          <tr>
            <th class="col" scope="col">Allowable Deficiency</th>
            <th class="col" scope="col">Issue Date</th>
            <th class="col" scope="col">End Date</th>
          </tr>
        </thead>
        <tbody>
          @for(allowableDeficiency of certificate.allowableDeficiencies; track certificate.personCertificateId) {
          <tr scope="row" class="area-row">
            <td class="col">{{allowableDeficiency.description}}</td>
            <td class="col">{{allowableDeficiency.startDate| date : 'MM/dd/yyyy'}}</td>
            <td class="col">{{allowableDeficiency.endDate| date : 'MM/dd/yyyy'}}</td>
          </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</section>
