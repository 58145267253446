import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SigninRedirectCallbackComponent } from './signin-redirect-callback/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './signout-redirect-callback/signout-redirect-callback.component';
import { TokenInterceptorProvider } from './interceptors/token.interceptor';
import { ToastInterceptorProvider } from './interceptors/toast.interceptor';


@NgModule({
  declarations: [
    SigninRedirectCallbackComponent,
    SignoutRedirectCallbackComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    TokenInterceptorProvider,
    ToastInterceptorProvider
  ]
})
export class AuthModule { }
