import { Component } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { AuthService } from 'src/auth/services/auth.service';
import { FormEvents } from 'src/shared/constants';
import { CodeTablesClient, EmailCodeDto, PersonEmailDto, ProfileClient } from 'src/shared/services/api.service';

@Component({
  selector: 'edportal-educator-contact-email',
  templateUrl: './educator-contact-email.component.html',
  styles: ``
})
export class EducatorContactEmailComponent {

  addEmailMode: boolean = false;
  selectedEmail!: PersonEmailDto | undefined;
  dataLoaded: boolean = false;
  emails!: PersonEmailDto[];
  emails$!: Observable<PersonEmailDto[]>;
  emailOptions!: EmailCodeDto[];

  constructor(private profileClient: ProfileClient, private codeTablesClient: CodeTablesClient,
     public authService: AuthService) { }

  ngOnInit() {
    this.loadEmails();
    this.codeTablesClient.getPersonEmailCodes().subscribe(x => this.emailOptions = x);
  }

  loadEmails() {
    this.profileClient.getPersonsEmailInfo()
    .pipe(
      finalize(() => this.dataLoaded = true)
    ).subscribe(x => this.emails = x);
  }

  enableAddEmail() {
    this.selectedEmail = undefined;
    this.addEmailMode = true;
  }

  handleFormEvent(emissionValue: FormEvents) {
    if (emissionValue == FormEvents.Cancelled) {
      this.selectedEmail = undefined;
      this.addEmailMode = false;
    } else if (emissionValue == FormEvents.Successful) {
      this.selectedEmail = undefined;
      this.addEmailMode = false;
      this.loadEmails();
    }
  }

  loadEditForm(email: PersonEmailDto) {
    this.selectedEmail = email;
    this.addEmailMode = true;
  }

  emailItemClasses(email: PersonEmailDto) {
    return {
      'editing': email.personEmailId == this.selectedEmail?.personEmailId
    };
  }
}
