<form #addOrUpdateEmailForm="ngForm" autocomplete="off">
  <div class="col-sm">
    <div class="form-group">
      <label for="email">Email</label>
      <input #email="ngModel" class="form-control" id="email" name="email"
        [(ngModel)]="addOrUpdatePersonsEmailCommand.email" [value]="addOrUpdatePersonsEmailCommand" pattern="\S+@\S+\.\S+$" email>
      <div validationErrors [for]="email.control" [errorEmitter]="errorEmitter"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-md">
      <div class="form-group">
        <label for="emailCodeId">Email Type</label>
        <ng-select #emailCodeId="ngModel" id="emailCodeId" name="emailCodeId" labelForId="emailCodeId" [closeOnSelect]="true"
          [multiple]="false" required [(ngModel)]="addOrUpdatePersonsEmailCommand.emailCodeId"
          (change)="removeUnknown()" placeholder="Select an email type...">
          <ng-option *ngFor="let emailOption of filteredEmailOptions" [value]="emailOption.emailCodeId"
            [disabled]="emailOption.isDisabled || emailOption.description.toLowerCase() === 'unknown'">{{emailOption.description}}</ng-option>
        </ng-select>
        <div validationErrors [for]="emailCodeId.control" [errorEmitter]="errorEmitter"></div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <button *ngIf="addOrUpdatePersonsEmailCommand.personEmailId" class="btn btn-sm btn-danger float-start"
        (click)="openConfirmationModal()" type="button">Delete</button>
      <div class="float-end">
        <button class="btn btn-sm btn-outline-secondary me-2" (click)="cancelAddEmail()" type="button">Cancel</button>
        <button class="btn btn-sm btn-primary" (click)="onSubmitEmail()" type="submit">Save</button>
      </div>
    </div>
  </div>
</form>
