import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'edportal-no-pending-app',
  templateUrl: './no-pending-app.component.html',
  styles: ``
})
export class NoPendingAppComponent {
  constructor(private activeModal: NgbActiveModal) {

  }

  onOk() {
    this.activeModal.close(true);
  }
}
