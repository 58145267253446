import { Injectable } from '@angular/core';
import { UserManager } from 'oidc-client';
import { Observable, firstValueFrom, from, map, of, switchMap, tap } from 'rxjs';
import { ConfigService } from 'src/shared/services/config.service';
import { JwtService } from './jwt.service';
import { AuthenticationMethods } from 'src/shared/constants';
import { Router } from '@angular/router';
import { AuthClient, CreateNonProdUserTokenCommand, TokenRefreshResult } from 'src/shared/services/api.service';
import { PendingApplicationService } from 'src/shared/services/pending-application.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _userManager!: UserManager;

  constructor(private configService: ConfigService, private jwtService: JwtService,
    private router: Router, private authClient: AuthClient, private pendingAppService: PendingApplicationService) {
  }

  get userManager(): UserManager {
    return this._userManager ?? new UserManager(
      {
        authority: this.configService.config.azureAppConfig.authority!,
        metadataUrl: this.configService.config.azureAppConfig.metadataUrl,
        client_id: this.configService.config.azureAppConfig.clientId!,
        redirect_uri: this.configService.config.azureAppConfig.redirectUri!,
        scope: 'openid',
        response_type: 'code',
        post_logout_redirect_uri: this.configService.config.azureAppConfig.postLogoutRedirectUri,
        prompt: 'login'
      }
    );
  }

  async login(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  async logout(): Promise<void> {
    const userId = this.jwtService.userId;
    await firstValueFrom(this.authClient.logout({
      userId: userId
    }));
    const isTestLogin = this.jwtService.authMethod === AuthenticationMethods.test;
    this.jwtService.removeToken();
    if (isTestLogin) {
      this.router.navigateByUrl('/launch');
    } else {
      this.userManager.signoutRedirect();
    }
    ;
  }

  isLoggedIn(): Observable<boolean> {
    const isTokenValid = this.jwtService.getToken() && this.jwtService.isTokenRenewable();

    return of(!!isTokenValid);
  }

  refreshToken(): Observable<TokenRefreshResult> {
    return this.authClient.refreshToken()
      .pipe(tap(x => this.jwtService.setToken(x.token!)));
  }


  completeTestLogin(ein: string): Observable<boolean> {
    const command: CreateNonProdUserTokenCommand = {
      ein: ein
    }
    return this.authClient.createNonProdUserToken(command)
      .pipe(map(res => {
        this.jwtService.setToken(res.localToken!);
        this.userManager.removeUser();

        // alert that a pending app was cleared.
        this.pendingAppService.pendingAppClearedSubject.next(res.wasPendingAppClearedSinceLastLogin)

        return res.isEducatorCorrelated;
      }));
  }

  completeLogin(): Observable<boolean> {
    return from(this.userManager.signinRedirectCallback())
      .pipe(switchMap(idpUser => {
        return this.authClient.exchangeIdpToken({
          idpIdentityToken: idpUser.id_token!
        })
      }))
      .pipe(map(res => {
        this.jwtService.setToken(res.localToken!);
        // Remove the idp user info if we've exchanged the token.
        this.userManager.removeUser();

        // alert that a pending app was cleared.
        this.pendingAppService.pendingAppClearedSubject.next(res.wasPendingAppClearedSinceLastLogin)

        return res.isEducatorCorrelated;
      }));
  };

  completeLogout() {
    return this.userManager.signoutRedirectCallback();
  }
}
