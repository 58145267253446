<section class="mt-2">
  <div *ngIf="!loading" class="text-center py-5">
    @if (!noResults) {
    <h2 class="display-4">Results</h2>
    <p class="lead">Position(s) and Grade(s) are displayed for the current {{fiscalYear}} school year only.</p>
    }
    <div *ngIf="!initialLoad && !loading && educators.length"
      class="btn-group d-flex justify-content-center flex-grow-btn-group" role="group">
      <div class="btn-group" role="group">
        <button type="button" class="btn btn-outline-primary dropdown-toggle border-end-0" data-bs-toggle="dropdown"
          aria-expanded="false">
          Sort by Last Name
        </button>
        <ul class="dropdown-menu">
          <button class="dropdown-item" (click)="onSortChange(sortField.LastName, sortDirection.ASC)">Last Name
            Ascending (A-Z)</button>
          <li><a class="dropdown-item" (click)="onSortChange(sortField.LastName, sortDirection.DESC)">Last Name
              Descending (Z-A)</a></li>
        </ul>
      </div>
      <div class="btn-group" role="group">
        <button type="button" class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown"
          aria-expanded="false">
          Sort by First Name
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" (click)="onSortChange(sortField.FirstName, sortDirection.ASC)">Last First
              Ascending (A-Z)</a></li>
          <li><a class="dropdown-item" (click)="onSortChange(sortField.FirstName, sortDirection.DESC)">Last First
              Descending (Z-A)</a></li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="row py-5">
    <div class="col">
      <ngx-skeleton-loader count="5" [theme]="{ height: '5rem' }"></ngx-skeleton-loader>
    </div>
    <div class="col">
      <ngx-skeleton-loader count="5" [theme]="{ height: '5rem' }"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="row row-cols-1 row-cols-lg-2 g-3">
    @for(educator of educators; track educator.ein) {
    <div class="col">
      <div class="card card-body border-0 bg-white shadow-sm mb-2">
        <h2 class="card-title h3 fw-bold">
          <a class="text-decoration-none clickable"
            (click)="navigateEducatorDetail(educator.personId)">{{educator.lastName}},
            {{educator.firstName}} {{educator.middleInitial}}</a>
          <ng-template #alternateNamesPopoverContent>
            <p class="popover-spacing" *ngFor="let alternateName of educator.alternateNames">{{alternateName}}</p>
          </ng-template>
          @if(educator.alternateNames.length)
          {
            <i class="bi bi-info-circle pointer ms-2 clickable"
              [ngbPopover]="alternateNamesPopoverContent" (click)="stopPropagation($event)"
              popoverTitle="Alternate Names" certTooltip="Alternate Names">
            </i>
          }
        </h2>
        <p class="card-text lead"><span class="badge text-bg-light">EIN: {{educator.ein}}</span></p>
        @if (educator.positions.length) {
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Position</th>
                <th scope="col">Grades</th>
              </tr>
            </thead>
            <tbody>
              @for(position of educator.positions; track position.description) {
              <tr>
                <th scope="row">{{position.description}}</th>
                <td>{{position.gradesTaught}}</td>
              </tr>
              }
            </tbody>
          </table>
        </div>
        }
        @else {
        <p class="lead">There is no position data available for this educator.</p>
        }
      </div>
    </div>
    }
  </div>
  <div *ngIf="noResults" class="card card-body text-bg-light text-center">
    <p class="card-text">No Results Found! Please try different search terms. Be sure to use at least one search
      criterion.</p>
  </div>
</section>
