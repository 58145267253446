import { NgModule } from '@angular/core';
import { AppContainerComponent } from './app-container/app-container.component';
import { RouterModule, Routes, provideRouter } from '@angular/router';
import { SigninRedirectCallbackComponent } from 'src/auth/signin-redirect-callback/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from 'src/auth/signout-redirect-callback/signout-redirect-callback.component';
import { SplashModule } from 'src/splash/splash.module';
import { RequireConfirmedEducatorGuard } from './route-guards/require-confirmed-educator.guard';
import { IsLoggedInGuard } from './route-guards/is-logged-in.guard';
import { PublicModule } from 'src/public/public.module';

const appRoutes: Routes = [
  {
    path: 'launch', loadChildren: () => SplashModule
  },
  {
    path: 'public', loadChildren: () => PublicModule
  },
  {
    path: '', component: AppContainerComponent, canActivate: [IsLoggedInGuard, RequireConfirmedEducatorGuard], children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', loadChildren: () => import('../home/home.module').then(m => m.HomeModule) },
      { path: 'my-certificates', loadChildren: () => import('../my-certificates/my-certificates-module').then(m => m.MyCertificatesModule) },
      { path: 'application', loadChildren: () => import('../pending-application/pending-application.module').then(m => m.PendingApplicationModule) },
      { path: 'applications', loadChildren: () => import('../applications/applications.module').then(m => m.ApplicationsModule) },
      { path: 'profile', loadChildren: () => import('../profile/profile.module').then(m => m.ProfileModule) },
      { path: 'notifications', loadChildren: () => import('../notifications/notifications.module').then(m => m.NotificationsModule) },
      { path: 'contact-us', loadChildren: () => import('../contact/contact.module').then(m => m.ContactModule) },
      { path: 'complete-application', loadChildren: () => import('../complete-application/complete-application.module').then(m => m.CompleteApplicationModule) },
      { path: 'upload-documents', loadChildren: () => import('../upload-documents/upload-documents.module').then(m => m.UploadDocumentsModule) }
    ]
  },
  { path: 'signin-callback', component: SigninRedirectCallbackComponent },
  { path: 'signout-callback', component: SignoutRedirectCallbackComponent },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
