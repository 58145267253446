<body class="d-flex flex-column min-vh-100 bg-gray-100">
  <header class="d-flex flex-column flex-md-row bg-white justify-content-md-between align-items-center p-3">
    <div>
      <img src="/assets/azde-horizontal-logo-new.png" alt="Arizona Department of Education" height="90" />
    </div>
    <div>
      <a alt="New Search" title="New Search" class="btn btn-primary btn-lg" (click)="navigateSearch()"><i
          class="bi bi-search me-3"></i>New Search</a>
    </div>
  </header>
  <main class="container py-4 px-3 mx-auto flex-grow-1" *ngIf="dataLoaded">
    <section class="card card-body bg-white">
      <div class="row">
        <div class="col-lg">
          <span class="small">Name</span>
          <h1>{{educator.name}}</h1>
        </div>
        <div class="col-lg">
          <span class="small">Educator ID</span>
          <p class="lead">{{educator.educatorId}}</p>
        </div>
        <div class="col-lg">
          <span class="small">Disciplinary History</span>
          @if (educator.hasDiscipline) {
            <ng-template #disciplinePopover>
              Educator has disciplinary history on file. Please contact the Arizona State Board of Education - Investigative Unit at
                <u>investigation&#64;azsbe.az.gov</u> for further details. The Certification Unit is unable to provide additional details.
            </ng-template>
            <p class="lead">
              <span class="badge rounded-pill text-bg-danger">Yes
                <i class="bi bi-info-circle pointer clickable ms-1" [popoverTitle]="'Disciplinary History'" [ngbPopover]="disciplinePopover"
                 [autoClose]="'outside'"></i>
            </span>
            </p>
          }
          @else {
            <p class="lead"><span class="badge rounded-pill text-bg-secondary">None</span></p>
          }
        </div>
      </div>
      @if (educator.positions.length) {
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Position</th>
              <th scope="col">Grades</th>
            </tr>
          </thead>
          <tbody>
            @for (position of educator.positions; track personId) {
            <tr>
              <th scope="row">{{position.description}}</th>
              <td>{{position.gradesTaught}}</td>
            </tr>
            }
          </tbody>
        </table>
      </div>
      }
      @else {
      <p class="lead">There is no position data available for this educator.</p>
      }
    </section>
    <h2 class="ps-2 pt-3">Certificate(s)</h2>
    @for (certificate of educator.certificates; track certificate.personCertificateId) {
    <edportal-educator-certificate-detail [certificate]="certificate"></edportal-educator-certificate-detail>
    }
    @if (dataLoaded && educator.certificates.length) {
    <edportal-educator-approved-area-detail
      [approvedAreas]="educator.approvedAreas"></edportal-educator-approved-area-detail>
    <edportal-educator-endorsement-detail [endorsements]="educator.endorsements"></edportal-educator-endorsement-detail>
    }
    @else {
    <p class="lead ps-4">This educator does not have any certificates.</p>
    }
  </main>
  <div *ngIf="!dataLoaded" class="container py-4 px-3 mx-auto flex-grow-1 bg-gray-100">
    <ngx-skeleton-loader count="3" [theme]="{ height: '13rem' }"></ngx-skeleton-loader>
  </div>
  <footer class="mt-auto">
    <edportal-footer/>
  </footer>
</body>
