<div class="position-relative m-4">
  <progress class="progress-bar" aria-valuemin="0" aria-valuemax="100" aria-label="Application Progress Bar"></progress>
  <div *ngFor="let phaseNumber of [1, 2, 3, 4]; let i = index">
    <span [edPortalTooltip]="getTooltip(service, phaseNumber)"
      class="position-absolute translate-middle top-0 btn pill btn-sm filled-pill rounded-pill centered-icon"
      [ngClass]="getButtonClass(service, phaseNumber)" [ngStyle]="{'left': getLeftPosition(i)}">
      <i [ngClass]="getIcon(service, phaseNumber)"></i>
    </span>
  </div>
</div>
