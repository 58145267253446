<div class="form-group">
  <div class="row align-items-center">
    <div class="col-lg">
      <h3 class="h5 fw-normal">Mailing Address</h3>
    </div>
    <div class="col-lg text-end">
      <div *ngIf="!addAddressMode && !address">
        <button class="btn btn-primary btn-sm" (click)="enableAddAddress()">Add</button>
      </div>
    </div>
  </div>
  <ul class="list-group mt-3">
    @if (!dataLoaded) {
      <li>
        <ngx-skeleton-loader *ngIf="!dataLoaded" count="1" [theme]="{height: '5rem'}"></ngx-skeleton-loader>
      </li>
    }
    @else if (!addAddressMode && dataLoaded) {
      <li class="list-group-item clickable" (click)="loadEditForm()">
        <div *ngIf="!!address" class="d-flex justify-content-between my-1">
          <div class="h6 fw-normal">
            {{address.line1}} {{address.line2}} <br />
            {{address.city}},
            <span *ngIf="address.stateCode != null">{{address.stateCode}}</span>
            <span *ngIf="address.cityStateProvince != null">{{address.cityStateProvince}}</span>
            {{address.zipCode}}<span *ngIf="address.zipPlusFourCode != null">+{{address.zipPlusFourCode}}</span>
          </div>
          <div>
            <i certTooltip="Edit" class="edit-icon-lg"></i>
          </div>
        </div>

        <p *ngIf="!address" class="h6 fw-normal m-1 py-3">
          There is no address on file for this educator
        </p>
      </li>
    }
    @else if (addAddressMode && dataLoaded) {
      <li class="list-group-item">
        <edportal-educator-contact-address-form [address]="selectedAddress" [states]="states"
          (addressChanged)="handleFormEvent($event)"></edportal-educator-contact-address-form>
      </li>
    }
  </ul>
</div>
