import { Injectable } from '@angular/core';
import { PendingAppToDoItemStatus, PendingApplicationDto, PendingServiceItemStage, PendingToDoItemDto, ToDoTaskType } from './api.service';
import { Router } from '@angular/router';
import { Payload, ServiceItemLauncherService } from 'src/pending-application/services/service-item-launcher.service';
import { ServiceType } from 'src/pending-application/pending-app-summary/service-item-launch-config';

@Injectable({
  providedIn: 'root'
})
export class PendingAppNavigationService {
  constructor(private router: Router, private serviceItemLauncherService: ServiceItemLauncherService) { }

  goToSummary(pendingAppDto?: PendingApplicationDto) {
    // Should only navigate if there are some that are still not skipped.
    if (pendingAppDto?.toDoItems.some(x => x.status == PendingAppToDoItemStatus.Open)) {
      this.router.navigateByUrl('/application/todos');
    } else {
      this.router.navigateByUrl('/application/summary');
    }
  }

  goToQualificationPaths(pendingServiceItemId: number) {
    this.router.navigate(['application/qualification-paths'], { queryParams: { pendingServiceItemId: pendingServiceItemId } });
  }

  goToQuestions(pendingServiceItemId: number) {
    this.router.navigate(['application/question-summary'], { queryParams: { pendingServiceItemId: pendingServiceItemId } });
  }

  goToUpdateServiceItem(pendingServiceItemId: number, stage: PendingServiceItemStage) {
    switch (stage) {
      case PendingServiceItemStage.Created:
        this.goToQualificationPaths(pendingServiceItemId);
        break;
      case PendingServiceItemStage.QualificationPaths:
      case PendingServiceItemStage.ServiceQuestions:
        this.goToQuestions(pendingServiceItemId);
        break;
    }
  }

  navigateToToDoTask(taskType: ToDoTaskType, payload: Payload | null) {
    switch (taskType) {
      case ToDoTaskType.DeficiencyRemoval:
        this.serviceItemLauncherService.launch(ServiceType.RemoveDeficiency, payload!);
        break;
      case ToDoTaskType.CertificateRenewal:
        this.serviceItemLauncherService.launch(ServiceType.Renew, payload!);
        break;
      case ToDoTaskType.IR:
        this.serviceItemLauncherService.launch(ServiceType.IRCertificate, payload!);
        break;
      case ToDoTaskType.W9Required:
        this.router.navigate(['/upload-documents'], {
          queryParams: { needsW9: true }
        });
        break;
      case ToDoTaskType.FingerprintExpiringSoon:
      case ToDoTaskType.FingerprintExpired:

      default:
        console.log('task not implemented yet');
        break;
    }
  }
}
