import { Component } from '@angular/core';

@Component({
  selector: 'edportal-public-layout',
  templateUrl: './public-layout.component.html',
  styles: ``
})
export class PublicLayoutComponent {

}
