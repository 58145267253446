<section class="mt-4 card card-body bg-white border-0 shadow-sm">
  <h3 class="h4">Endorsements</h3>
  <div class="row">
    <div class="table-container d-flex flex-column mb-2 mt-3 mx-2">
      @if (endorsements.length) {
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="col" scope="col">Endorsement</th>
              <th class="col" scope="col">Endorsement Level</th>
            </tr>
          </thead>
          <tbody>
            <tr scope="row" *ngFor="let endorsement of endorsements">
              <td class="col">{{endorsement.endorsementCodeDescription}}</td>
              <td class="col-6">{{endorsement.endorsementLevelDescription}}</td>
            </tr>
          </tbody>
        </table>
      }
      @else {
        <p class="lead">This educator does not have any endorsements.</p>
      }
    </div>
  </div>
</section>
