import { Component, Input } from '@angular/core';
import { Toast } from 'bootstrap';
import { ToastConfig } from 'src/shared/services/toast.service';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'edportal-toast',
  templateUrl: './toast.component.html',
  styles: ``
})
export class ToastComponent {
  @Input() public toastConfig!: ToastConfig;
  public toastId: string;

  constructor() {
    this.toastId = uuid();
  }

  ngAfterViewInit() {
    const toastEl = document.getElementById(this.toastId) as Element;
    const toast = new Toast(toastEl, {
      autohide: this.toastConfig.autoHide ? this.toastConfig.autoHide : false,
      delay: 2500
    })

    toast.show();
  }
}
