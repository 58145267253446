import { Injectable } from '@angular/core';
import { ServiceType, toServiceType } from '../pending-app-summary/service-item-launch-config';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuid } from 'uuid';
import { PendingApplicationService } from 'src/shared/services/pending-application.service';
import { Observable, catchError, map, of } from 'rxjs';
import { ToDoBaseDto, ToDoTaskType } from 'src/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceItemLauncherService {
  private payloadCache = new Map<string, any>();

  constructor(private router: Router, private pendingAppService: PendingApplicationService) { }

  launch(serviceType: ServiceType, payload?: Payload) {
    let payloadId;

    if (payload) {
      payloadId = this.addPayload(payload);
    }

    switch (serviceType) {
      case ServiceType.AddCertificate:
        this.routeWithPayload('/application/add-certificate', payloadId);
        break;
      case ServiceType.AddArea:
        this.routeWithPayload('/application/add-approved-area', payloadId);
        break;
      case ServiceType.RemoveDeficiency:
        this.routeWithPayload('/application/remove-deficiency', payloadId);
        break;
      case ServiceType.AddEndorsement:
        this.routeWithPayload('/application/add-endorsement', payloadId);
        break;
      case ServiceType.Renew:
        this.routeWithPayload('/application/renew-certificate', payloadId);
        break;
      case ServiceType.RequestCopyOfCert:
        this.routeWithPayload('/application/copy-certificate', payloadId);
        break;
      case ServiceType.ChangeName:
        this.routeWithPayload('/application/name-change', payloadId);
        break;
      case ServiceType.IRCertificate:
        this.routeWithPayload('/application/institutional-recommendation', payloadId);
        break;
      case ServiceType.Extension:
        this.routeWithPayload('/application/extension', payloadId);
        break;
      default:
        console.log(`${serviceType.toString()} not implemented yet.`)
    }
  }

  relaunch(pendingServiceItemId: number) {
    this.pendingAppService.deleteServiceItem(pendingServiceItemId).subscribe(x => {
      this.launch(toServiceType(x.serviceCode));
    });
  }

  routeWithPayload(launcherUrl: string, payloadId?: string) {
    const queryParams = payloadId ? { queryParams: { payloadId } } : {};
    this.router.navigate([launcherUrl], queryParams);
  }

  addPayload(payload: any): string {
    const payloadId = uuid();
    this.payloadCache.set(payloadId, payload);

    return payloadId;
  }

  getPayloadFromRoute(route: ActivatedRoute): Observable<any> {
    return route.queryParams.pipe(
      map(params => {
          const payloadId = params['payloadId'];
          if (payloadId) {
              return this.getPayload(payloadId);
          }
          throw new Error('No payload ID provided.');
      }),
      catchError(() => {
          return of(null);
      })
  );
  }

  getPayload(payloadId: string) {
    return this.payloadCache.get(payloadId);
  }

  addPayloadByTaskTypeAndData(type: ToDoTaskType, data: any): Payload | null {
    return this.getPayloadByToDoTaskTypeWithData(type, data);
  }

  getPayloadByToDoTaskType(base: ToDoBaseDto): Payload | null {
    const baseData: any = this.getDataFromBase(base);
    return this.getPayloadByToDoTaskTypeWithData(base.type, baseData);
  }

  getDataFromBase(base: ToDoBaseDto): any {
    switch (base.type) {
      case ToDoTaskType.DeficiencyRemoval:
        return base.deficiencyRemovalPayload?.allowableDeficiencyCodeId;
      case ToDoTaskType.CertificateRenewal:
        return base.renewalPayload?.personCertificateId;
      case ToDoTaskType.IR:
        return base.institutionalRecommendationPayload?.personPPIRecommendationId;
      case ToDoTaskType.FingerprintExpiringSoon:
      case ToDoTaskType.FingerprintExpired:
      case ToDoTaskType.W9Required:
      default:
        return null;
    }
  }

  getPayloadByToDoTaskTypeWithData(type: ToDoTaskType, data: any): Payload | null {
    switch (type) {
      case ToDoTaskType.DeficiencyRemoval:
        return {
          data: Array.isArray(data) ? data : [data],
          message: 'We have auto-selected the deficiency described in the to-do task. If there are additional deficiencies you want to remove, add them to the selection above and click "Next" to continue.'
        };
      case ToDoTaskType.CertificateRenewal:
        return {
          data: data,
          message: 'We have auto-selected the certificate to renew described in the to-do task. If there are additional certificates you want to renew, add them to the selection above and click "Next" to continue.'
        };
      case ToDoTaskType.IR:
        return {
          data: data,
          message: 'We have auto-selected the recommendation described in the to-do task. Click "Next" to continue with this Institutional Recommendation.'
        };
      case ToDoTaskType.FingerprintExpiringSoon:
      case ToDoTaskType.FingerprintExpired:
      case ToDoTaskType.W9Required:
      default:
        return null;
    }
  }
}

export interface Payload {
  data: any;
  message: string;
}
