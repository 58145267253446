<div class="modal-content">
  <div class="modal-header bg-primary-100">
    <h5 class="modal-title">Application Cleared</h5>
  </div>
  <div class="modal-body">
    Pending applications are cleared on a nightly basis. You'll need to restart your application and complete it in the same day. Click the button below to re-start your application.
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-primary" (click)="onOk()">Ok</button>
  </div>
</div>
