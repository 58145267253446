import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'edportal-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styles: ``
})
export class ConfirmationModalComponent {
  message!: string;
  header!: string;
  invertButtons!: boolean;
  confirmOnly: boolean = false;
  headerBackgroundClass!: string | undefined;

  constructor(private activeModal: NgbActiveModal) { }

  onConfirm(confirmed: boolean): void {
    this.activeModal.close(confirmed);
  }

  getHeaderClass(): string {
    return this.headerBackgroundClass ? this.headerBackgroundClass! : 'bg-primary-100';
  }
}
