<div class="collapse row" id="disclaimer">
  <div class="col small p-4">
    <p>“Discipline Action on File” is displayed if an educator's certificate(s) has been revoked, suspended,
      surrendered, or denied by the Arizona State Board of Education or if similar discipline has been reported by
      another state to the National Association of State Directors of Teacher Education and Certification
      (NASDTEC).</p>
    <p>Questions regarding educator discipline can be directed to the Arizona Department of Education
      Investigations Unit at <a href="tel:+1602542972" title="Call (602) 542-2972 Now"
        alt="Call (602) 542-2972 Now">602-542-2972</a> or <a href="mailto:investigation@azed.gov"
        title="Email Investigation at AZDE" alt="Email Investigation at AZDE">investigation&#64;azed.gov</a>. This
      site does not display the status of an educator's fingerprint clearance card, which is required for teaching
      in Arizona and issued by the Arizona Department of Public Safety Fingerprint Clearance Card Unit.</p>
    <p>Please visit the Arizona Department of Education Certification Unit at <a
        href="http://www.azed.gov/educator-certification/" target="_blank" rel="noopener">Educator Certification</a> for more
      information about teacher certification.</p>
    <p>Questions regarding certification can be directed to<br /><a href="tel:+16025424367"
        title="Call (602) 542-4367 Now" alt="Call (602) 542-4367 Now">(602) 542-4367</a> or <a
        href="mailto:certification@azed.gov" rel="noopener" title="Email Certifications at AZDE"
        alt="Email Certifications at AZDE">Certification&#64;azed.gov</a>.</p>
  </div>
</div>
