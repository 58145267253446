import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ToastConfig {
  title: string,
  body: string,
  type: 'success' | 'warning' | 'danger' | 'info',
  autoHide: boolean | undefined,
  items?: string[] // Optional array of strings will be put into a bulleted list under the body message
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toasts = new Subject<ToastConfig>();
  toasts$ = this.toasts.asObservable();

  public success(message: string, autoHide: boolean | undefined, items?: string[], ): void {
    this.showToast('Success', message, 'success', autoHide, items);
  }

  public error(message: string, autoHide: boolean | undefined, items?: string[]): void {
    this.showToast('Error', message, 'danger', autoHide, items);
  }

  public reminder(message: string, autoHide: boolean | undefined, items?: string[]): void {
    this.showToast('Reminder', message, 'danger', autoHide, items);
  }

  public warn(message: string, autoHide: boolean | undefined, items?: string[]): void {
    this.showToast('Warning', message, 'warning', autoHide, items);
  }

  public info(message: string, autoHide: boolean | undefined, items?: string[]): void {
    this.showToast('Information', message, 'info', autoHide, items);
  }

  private showToast(title: string, message: string, type: 'success' | 'warning' | 'danger' | 'info', autoHide: boolean | undefined, items?: string[]): void {
    const toast: ToastConfig = {
      title,
      body: message,
      type,
      autoHide: autoHide,
      items: items || undefined
    };
    this.toasts.next(toast);
  }
}
