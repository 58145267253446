import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { AuthService } from 'src/auth/services/auth.service';
import { RedirectUrlService } from 'src/shared/services/redirect-url.service';

@Injectable({
  providedIn: 'root'
})
export class IsLoggedInGuard  {
  constructor(private authService: AuthService, private router: Router, private redirectUrlService: RedirectUrlService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authService.isLoggedIn().pipe(map(isLoggedIn => {
      if (!isLoggedIn) {
        this.redirectUrlService.set(state.url)
        // If not logged in, we can return a URL tree to tell the router that auth is denied.
        return this.router.createUrlTree(['/launch']);
      }

      if (this.redirectUrlService.has() && this.redirectUrlService.validate()) {
        let url = this.redirectUrlService.getRoute()
        let params = this.redirectUrlService.getQueryParams()
        this.redirectUrlService.remove();
        return this.router.createUrlTree([url], {
          queryParams: params
        })
      }

      // If logged in, return true to allow the navigation
      return true;
    }),
      catchError(() => {
        // In case of an error, navigate to the login screen
        this.router.navigate(['/launch']);

        return of(false);
      })
    );

  }
}
