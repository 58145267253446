import { APP_INITIALIZER, Injectable, Provider } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { EdPortalClientConfig, ConfigClient } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config!: EdPortalClientConfig;

  constructor(private configClient: ConfigClient) { }

  async loadConfig(): Promise<EdPortalClientConfig> {
    this.config = await firstValueFrom(this.configClient.clientConfig())

    return this.config;
  }
}

export const ConfigServiceProvider: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  deps: [ConfigService],
  useFactory: (appConfigSvc: ConfigService) => {
    return async () => {
      await appConfigSvc.loadConfig();
    };
  }
}
