import { Component, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subject, catchError, concat, debounceTime, distinctUntilChanged, filter, of, switchMap, tap } from 'rxjs';
import { AuthService } from 'src/auth/services/auth.service';
import { AuthClient, EducatorEINDto, GetEducatorEINsQuery } from 'src/shared/services/api.service';
import { catchValidationErrors } from 'src/shared/validation/validator-utilities';

@Component({
  selector: 'edportal-educator-selector',
  templateUrl: './educator-selector.component.html',
  styles: ``
})
export class EducatorSelectorComponent {
  educators$!: Observable<any>;
  educatorsLoading = false;
  einSearchInput$ = new Subject<string>();
  selectedEducator!: EducatorEINDto;
  minLengthTerm = 3;

  @ViewChild('loginForm') form!: NgForm;

  errorEmitter = new EventEmitter<any>();

  constructor(private authService: AuthService, private router: Router, private authClient: AuthClient) {

  }

  ngOnInit(): void {
    this.loadEducators();
  }

  trackByFn(item: EducatorEINDto) {
    return item.ein;
  }

  onEducatorChange() {
    console.log(this.selectedEducator);
  }

  loadEducators() {
    this.educators$ = concat(
      of([]), // default items
      this.einSearchInput$.pipe(
        filter(einSearch => {
          return einSearch !== null && einSearch.length >= this.minLengthTerm
        }),
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => this.educatorsLoading = true),
        switchMap(einSearch => {
          const query: GetEducatorEINsQuery = {
            einSearch: einSearch
          };
          return this.authClient.getEducatorsToImpersonate(query).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.educatorsLoading = false)
          )
        })
      )
    );

  }

  login() {
    this.authService.completeTestLogin(this.selectedEducator.ein)
      .pipe(catchValidationErrors(this.form, this.errorEmitter))
      .subscribe(educatorIsCorrelated => {
        if (educatorIsCorrelated) {
          this.router.navigate(['/'], { replaceUrl: true });
        } else {
          this.router.navigate(['/verify/one'], { replaceUrl: true });
        }
      })
  }
}

