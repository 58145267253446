import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'edportal-educator-verification-stalled',
  templateUrl: './educator-verification-stalled.component.html',
  styles: ``
})
export class EducatorVerificationStalledComponent {
  constructor(private router: Router){}

  onContinue() {
      this.router.navigate(['/verify/one'], { replaceUrl: true });
  }
}
