import { Component } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { AuthService } from 'src/auth/services/auth.service';
import { FormEvents } from 'src/shared/constants';
import { CodeTablesClient, PersonAddressDto, ProfileClient, StateDto } from 'src/shared/services/api.service';

@Component({
  selector: 'edportal-educator-contact-address',
  templateUrl: './educator-contact-address.component.html',
  styles: ``
})
export class EducatorContactAddressComponent {
  addAddressMode: boolean = false;
  selectedAddress!: PersonAddressDto;
  states$!: Observable<StateDto[]>;
  dataLoaded: boolean = false;
  address!: PersonAddressDto;
  states!: StateDto[];

  constructor(private codeTablesClient: CodeTablesClient,
    private profileClient: ProfileClient,
    public authService: AuthService) { }

  ngOnInit() {
    this.loadAddress();
    this.codeTablesClient.getStates().subscribe(x => this.states = x);
  }

  loadAddress() {
    this.profileClient.getPersonsAddressInfo()
      .pipe(
        finalize(() => this.dataLoaded = true)
      ).subscribe(x => this.address = x);
  }

  enableAddAddress() {
    this.addAddressMode = true;
  }

  handleFormEvent(emissionValue: FormEvents) {
    if (emissionValue == FormEvents.Cancelled) {
      this.addAddressMode = false;
    } else if (emissionValue == FormEvents.Successful) {
      this.addAddressMode = false;
      this.loadAddress();
    }
  }

  loadEditForm() {
    this.selectedAddress = this.address;
    this.addAddressMode = true;
  }
}
