import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { AppContainerComponent } from './app-container/app-container.component';
import { AppRoutingModule } from './app-routing.module';
import { SplashModule } from 'src/splash/splash.module';
import { SharedModule } from 'src/shared/shared.module';
import { AuthModule } from 'src/auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NoPendingAppComponent } from './no-pending-app/no-pending-app.component';
import { ToastComponent } from './toast/toast.component';
import { ToastContainerComponent } from './toast-container/toast-container.component';
import { UnhandledErrorComponent } from './unhandled-error/unhandled-error.component';
import { IssueCollectorComponent } from './nav-menu/issue-collector/issue-collector.component';

@NgModule({ declarations: [
        AppComponent,
        NavMenuComponent,
        AppContainerComponent,
        NoPendingAppComponent,
        ToastComponent,
        ToastContainerComponent,
        UnhandledErrorComponent,
        IssueCollectorComponent
    ],
    exports: [
        NgbModule
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        SplashModule,
        SharedModule,
        AuthModule,
        NgbModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
