import { animation, style, animate } from '@angular/animations';

export const slideUpFromBottomAnimation = animation([
  style({ transform: 'translateY(100%)', opacity: 0 }), // Start from below the view
  animate('{{ time }}', style({ transform: 'translateY(0)', opacity: 1 })), // Animate to the final position
]);

export const slideDownToBottomAnimation = animation([
  style({ transform: 'translateY(0)', opacity: 1 }), // Start from the current position
  animate('{{ time }}', style({ transform: 'translateY(100%)', opacity: 0 })), // Animate to below the view
]);

export const slideDownFromTopAnimation = animation([
  style({ transform: 'translateY(-100%)', opacity: 0 }), // Start from above the view
  animate('{{ time }}', style({ transform: 'translateY(0)', opacity: 1 })), // Animate to the final position
]);

export const slideUpToTopAnimation = animation([
  style({ transform: 'translateY(0)', opacity: 1 }), // Start from the current position
  animate('{{ time }}', style({ transform: 'translateY(-100%)', opacity: 0 })), // Animate to above the view
]);
