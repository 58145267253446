import { Component, EventEmitter, Input, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from 'src/shared/confirmation-modal/confirmation-modal.component';
import { FormEvents } from 'src/shared/constants';
import { AddOrUpdatePersonsEmailCommand, DeletePersonsEmailCommand, EmailCodeDto, PersonEmailDto, ProfileClient } from 'src/shared/services/api.service';
import { catchValidationErrors } from 'src/shared/validation/validator-utilities';

@Component({
  selector: 'edportal-educator-contact-email-form',
  templateUrl: './educator-contact-email-form.component.html',
  styleUrls: ['./educator-contact-email-form.component.scss']
})
export class EducatorContactEmailFormComponent {
  @Input() email!: PersonEmailDto | undefined;
  @Input() emailOptions!: EmailCodeDto[];
  @Output() emailChanged = new EventEmitter<FormEvents>();

  @ViewChild('addOrUpdateEmailForm') form!: NgForm;
  errorEmitter = new EventEmitter<any>();

  addOrUpdatePersonsEmailCommand!: AddOrUpdatePersonsEmailCommand;
  filteredEmailOptions!: EmailCodeDto[];

  constructor(
    private profileClient: ProfileClient,
    private modalService: NgbModal,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.assignCommandFromEmail();
    this.filterEmailOptions();
  }


  ngAfterViewInit() {
    this.renderer.selectRootElement('#email').focus();
  }

  assignCommandFromEmail() {
    this.addOrUpdatePersonsEmailCommand = {
      personEmailId: this.email?.personEmailId,
      emailCodeId: this.email?.emailCodeId ?? undefined,
      email: this.email?.email!
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.email) {
      this.assignCommandFromEmail();
      this.filterEmailOptions();
    }
  }

  cancelAddEmail() {
    this.emailChanged.emit(FormEvents.Cancelled);
  }

  onSubmitEmail() {
    const isAdd = !this.addOrUpdatePersonsEmailCommand?.personEmailId;

    const request = isAdd
      ? this.profileClient.addPersonsEmail(this.addOrUpdatePersonsEmailCommand)
      : this.profileClient.updatePersonsEmail(this.addOrUpdatePersonsEmailCommand);

      request
      .pipe(catchValidationErrors(this.form, this.errorEmitter))
      .subscribe({
        next: () => {
          this.emailChanged.emit(FormEvents.Successful);
        },
        error: () => {
          this.emailChanged.emit(FormEvents.Error);
        }
      });
  }

  openConfirmationModal() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      backdrop: 'static',
      size: 'sm',
      centered: true,
    });
    modalRef.componentInstance.header = 'Delete Email';
    modalRef.componentInstance.message = 'Are you sure you want to delete this email address? ';

    modalRef.result.then((result) => {
      if (result) {
        this.deleteExistingEmail();
      }
    });
  }

  deleteExistingEmail() {
    const deletePersonsEmailCommand: DeletePersonsEmailCommand = {
      personEmailId: this.email!.personEmailId
    };

    this.profileClient.deletePersonsEmail(deletePersonsEmailCommand).subscribe({
      next: () => {
        this.emailChanged.emit(FormEvents.Successful);
      },
      error: () => {
        this.emailChanged.emit(FormEvents.Error);
      }
    });
  }

  filterEmailOptions() {
    if (typeof this.email?.personEmailId === 'undefined') {
      this.filteredEmailOptions = this.emailOptions.filter(x => x.description.toLowerCase() !== 'unknown');
    } else {
      this.filteredEmailOptions = this.emailOptions;
    }
  }

  removeUnknown() {
    this.emailOptions = this.emailOptions.filter(x => x.description.toLowerCase() !== 'unknown');
  }
}
