import { Component } from '@angular/core';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'edportal-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent {
  isLoading: boolean = false;
  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
    this.loaderService.loaderSignal$.subscribe(x => this.isLoading = x);
  }
}
