import { Component } from '@angular/core';
import { Observable, finalize } from 'rxjs';
import { AuthService } from 'src/auth/services/auth.service';
import { FormEvents } from 'src/shared/constants';
import { CodeTablesClient, ProfileClient, PersonPhoneDto, PhoneCodeDto } from 'src/shared/services/api.service';

@Component({
  selector: 'edportal-educator-contact-phone',
  templateUrl: './educator-contact-phone.component.html',
  styles: ``
})
export class EducatorContactPhoneComponent {
  addPhoneMode: boolean = false;
  selectedPhoneNumber!: PersonPhoneDto | undefined;
  dataLoaded: boolean = false;
  phoneNumbers!: PersonPhoneDto[];
  phoneNumbers$!: Observable<PersonPhoneDto[]>;
  phoneOptions!: PhoneCodeDto[];

  constructor(private profileClient: ProfileClient, private codeTablesClient: CodeTablesClient,
    public authService: AuthService) { }

  ngOnInit() {
    this.loadPhoneNumbers();
    this.codeTablesClient.getPersonPhoneCodes().subscribe(x => this.phoneOptions = x);
  }

  loadPhoneNumbers() {
    this.profileClient.getPersonsPhoneInfo()
    .pipe(
      finalize(() => this.dataLoaded = true)
    ).subscribe(x => this.phoneNumbers = x);
  }

  enableAddPhone() {
    this.selectedPhoneNumber = undefined;
    this.addPhoneMode = true;
  }

  handleFormEvent(emissionValue: FormEvents) {
    if (emissionValue == FormEvents.Cancelled) {
      this.selectedPhoneNumber = undefined;
      this.addPhoneMode = false;
    } else if (emissionValue == FormEvents.Successful) {
      this.selectedPhoneNumber = undefined;
      this.addPhoneMode = false;
      this.loadPhoneNumbers();
    }
  }

  loadEditForm(phoneNumber: PersonPhoneDto) {
    this.selectedPhoneNumber = phoneNumber;
    this.addPhoneMode = true;
  }

  phoneNumberItemClasses(phoneNumber: PersonPhoneDto) {
    return {
      'editing': phoneNumber.personPhoneNumberId == this.selectedPhoneNumber?.personPhoneNumberId
    };
  }
}
