import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToDoListService {
  private refreshToDoListSubject = new Subject<void>();

  refreshToDoList$ = this.refreshToDoListSubject.asObservable();

  requestRefreshToDoList() {
    this.refreshToDoListSubject.next();
  }
}
