import { Component } from '@angular/core';
import { PendingApplicationService } from 'src/shared/services/pending-application.service';
import { trigger, style, transition, animate, useAnimation } from '@angular/animations';
import {  PendingApplicationDto } from 'src/shared/services/api.service';
import { PendingAppNavigationService } from 'src/shared/services/pending-app-navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { slideDownFromTopAnimation, slideUpToTopAnimation } from '../transitions/slide-down-animation';

@Component({
  selector: 'edportal-pending-app-banner',
  templateUrl: './pending-app-banner.component.html',
  styleUrl: './pending-app-banner.component.scss',
  animations: [
    trigger('slideDown', [
      transition(':enter', [
        useAnimation(slideDownFromTopAnimation, {
          params: { time: '0.5s' },
        }),
      ]),
      transition(':leave', [
        useAnimation(slideUpToTopAnimation, {
          params: { time: '0.5s' },
        }),
      ]),
    ]),
  ]
})
export class PendingAppBannerComponent {
  pendingApp!: PendingApplicationDto;

  constructor(private pendingAppService: PendingApplicationService, private appNavService: PendingAppNavigationService, private modalService: NgbModal) {
  }

  ngOnInit() {
    this.pendingAppService.fetchApp().subscribe(x => {
      this.pendingApp = x;
    })
  }

  get serviceItemCount() {
    return this.pendingApp?.pendingServiceItems?.length ?? 0;
  }

  get serviceItemCountExplanation() {
    return this.serviceItemCount == 0
      ? `You don't have any service items on your application. Click here to add one.`
      : `You have ${this.serviceItemCount} service item(s) on your application. Click here to add more or complete your application.`;
  }

  goToSummary(event: Event) {
    event.preventDefault();

    this.appNavService.goToSummary(this.pendingApp);
  }

  cancel(event: Event) {
    event.preventDefault();

    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      backdrop: true,
      keyboard: true,
      size: 'md',
      centered: true,
    });

    modalRef.componentInstance.header = 'Cancel Application?'
    modalRef.componentInstance.message = `Are you sure you want to cancel your application? All of your service items will be removed and your progress will be lost.`

    modalRef.result.then((result) => {
      if (result) {
        this.pendingAppService.cancel();
      }
    });
  }
}
