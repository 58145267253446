<div class="modal-content">
  <div class="modal-header" [class]="getHeaderClass()">
    <h5 class="modal-title">{{ header }}</h5>
  </div>
  <div class="modal-body">
    <p>{{ message }}</p>
  </div>
  <div class="modal-footer d-flex">
    @if(confirmOnly) {
      <button type="button" class="btn btn-secondary" (click)="onConfirm(false)">I Understand</button>
    }
    @else {
      @if(invertButtons) {
        <button type="button" class="btn btn-secondary" (click)="onConfirm(true)">Yes</button>
        <button type="button" class="btn btn-primary" (click)="onConfirm(false)">No</button>
      }
      @else {
        <button type="button" class="btn btn-secondary" (click)="onConfirm(false)">No</button>
        <button type="button" class="btn btn-primary" (click)="onConfirm(true)">Yes</button>
      }
    }
  </div>
</div>
