import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, filter, take } from 'rxjs';
import { NoPendingAppComponent } from 'src/app/no-pending-app/no-pending-app.component';
import { AuthService } from 'src/auth/services/auth.service';
import { IdleService } from 'src/auth/services/idle.service';
import { ConfigService } from 'src/shared/services/config.service';
import { PendingApplicationService } from 'src/shared/services/pending-application.service';

@Component({
  selector: 'edportal-app-container',
  templateUrl: './app-container.component.html',
  styles: ``
})
export class AppContainerComponent {
  private stop$ = new Subject<void>();
  environment?: string;

  isLoading: boolean = true;

  constructor(private configService: ConfigService, private pendingAppService: PendingApplicationService,
    private modalService: NgbModal, private authService: AuthService, private idleService: IdleService) {
  }

  ngOnInit() {
    this.environment = this.configService.config.environment;
    this.pendingAppService.pendingAppCleared$
      .pipe(filter(x => x === true), take(1))
      .subscribe(x => {
        this.handlePendingAppClearedNotification(x);
      })

    this.authService.isLoggedIn().subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.setIdleTime();
      }
    });
  }

  private setIdleTime() {
    const idleTimeoutInSeconds: number = this.configService.config.idleTimeoutInSeconds;

    this.idleService.createIdleTimeoutStream(idleTimeoutInSeconds * 1000, this.stop$).subscribe((isTimeExpired: boolean) => {
      if (isTimeExpired) {
        console.log('Idle session timeout expired.')
        this.authService.logout();
      }
    });
  }

  handlePendingAppClearedNotification(wasPendingAppClearedSinceLastLogin: boolean) {
    if (wasPendingAppClearedSinceLastLogin) {
      this.modalService.open(NoPendingAppComponent, {
        backdrop: true,
        keyboard: true,
        size: 'md',
        centered: true,
      });
    }
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}
