<h1 class="h3 card-title text-center my-4">ADE Educator Portal</h1>
<p>You are accessing a restricted information system. Any unauthorized use of this system is prohibited and
  subject to criminal and/or civil penalties. You are responsible for the accuracy of the information you
  provide or change while using the system. You are furthermore responsible for maintaining the
  confidentiality of all information within the system. All use of this system is monitored, recorded, and
  subject to audit; your use of the system indicates your consent to monitoring and recording.</p>
<p>To review the full Terms and Conditions, visit <a target="_blank"
    href="https://adeconnect.azed.gov/Account/TermsAndConditions" rel="noopener">https://adeconnect.azed.gov/Account/TermsAndConditions</a>
</p>
<p>By clicking <em>“I Agree”</em> you consent to these terms. If you do not agree, close the browser window.</p>
<button class="btn btn-lg btn-primary w-100" (click)="agree()">I Agree</button>
