import { Component, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { EducatorVerificationStepTwoDpsCommand, AuthClient } from 'src/shared/services/api.service';
import { catchValidationErrors } from 'src/shared/validation/validator-utilities';

@Component({
  selector: 'edportal-educator-verification-step-two-dps',
  templateUrl: './educator-verification-step-two-dps.component.html',
  styles: ``
})
export class EducatorVerificationStepTwoDpsComponent {
  @ViewChild('stepTwoForm') form!: NgForm;
  stepTwoCommand: EducatorVerificationStepTwoDpsCommand = {}
  errorEmitter = new EventEmitter<any>();

  constructor(private authClient: AuthClient, private router: Router) {

  }

  onContinue() {
    this.authClient.completeStepTwoVerificationDps(this.stepTwoCommand)
      .pipe(catchValidationErrors(this.form, this.errorEmitter))
      .subscribe(complete => {
        if (complete) {
          this.router.navigate(['/verify/success'], { replaceUrl: true });
        } else {
          this.router.navigate(['/verify/failed'], { replaceUrl: true });
        }
      })
  }
}
