import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EducatorVerificationRouteData } from '../splash-routing.module';
import { AuthClient } from 'src/shared/services/api.service';

@Component({
  selector: 'edportal-educator-verification-result',
  templateUrl: './educator-verification-result.component.html',
  styleUrls: ['./educator-verification-result.component.scss']
})
export class EducatorVerificationResultComponent {

  isVerified!: boolean;

  constructor(private route: ActivatedRoute, private router: Router, private authClient: AuthClient) { }

  ngOnInit() {
    this.isVerified = (this.route.snapshot.data as EducatorVerificationRouteData).verifyResult;
  }

  onContinue() {
    if (this.isVerified) {
      this.authClient.completeEducatorVerification().subscribe(complete => {
        if (complete) {
          this.router.navigate(['/'], { replaceUrl: true});
        } else {
          this.isVerified = false;
        }
      })
    } else {
      this.router.navigate(['/verify/one'], { replaceUrl: true});
    }
  }

  onTryAgain(event: Event) {
    event.preventDefault();
    this.router.navigate(['/verify/one'], { replaceUrl: true});
  }
}
