import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/shared/shared.module';
import { PublicRoutingModule } from './public-routing.module';
import { PublicSearchFormComponent } from './public-search-form/public-search-form.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { FooterComponent } from './footer/footer.component';
import { PublicSearchResultsComponent } from './public-search-results/public-search-results.component';
import { EducatorDetailComponent } from './educator-detail/educator-detail.component';
import { EducatorCertificateDetailComponent } from './educator-detail/educator-certificate-detail/educator-certificate-detail.component';
import { EducatorApprovedAreaDetailComponent } from './educator-detail/educator-approved-area-detail/educator-approved-area-detail.component';
import { EducatorEndorsementDetailComponent } from './educator-detail/educator-endorsement-detail/educator-endorsement-detail.component';

@NgModule({
  declarations: [
    PublicLayoutComponent,
    PublicSearchFormComponent,
    DisclaimerComponent,
    FooterComponent,
    PublicSearchResultsComponent,
    EducatorDetailComponent,
    EducatorCertificateDetailComponent,
    EducatorApprovedAreaDetailComponent,
    EducatorEndorsementDetailComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    PublicRoutingModule
  ]
})
export class PublicModule { }
