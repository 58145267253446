import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplashRoutingModule } from './splash-routing.module';
import { TosComponent } from './tos/tos.component';
import { AuthModeSelectorComponent } from './auth-mode-selector/auth-mode-selector.component';
import { EducatorSelectorComponent } from './educator-selector/educator-selector.component';
import { SplashLayoutComponent } from './splash-layout/splash-layout.component';
import { SharedModule } from 'src/shared/shared.module';
import { EducatorVerificationStepOneComponent } from './educator-verification-step-one/educator-verification-step-one.component';
import { EducatorVerificationStepTwoContactComponent } from './educator-verification-step-two-contact/educator-verification-step-two-contact.component';
import { EducatorVerificationResultComponent } from './educator-verification-result/educator-verification-result.component';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { EducatorVerificationStepTwoDpsComponent } from './educator-verification-step-two-dps/educator-verification-step-two-dps.component';
import { EducatorVerificationStalledComponent } from './educator-verification-stalled/educator-verification-stalled.component';
import { AccountRegistrationComponent } from './account-registration/account-registration.component';

@NgModule({
  declarations: [
    TosComponent,
    AuthModeSelectorComponent,
    EducatorSelectorComponent,
    SplashLayoutComponent,
    EducatorVerificationStepOneComponent,
    EducatorVerificationStepTwoContactComponent,
    EducatorVerificationResultComponent,
    EducatorVerificationStepTwoDpsComponent,
    EducatorVerificationStalledComponent,
    AccountRegistrationComponent
  ],
  imports: [
    CommonModule,
    SplashRoutingModule,
    SharedModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [
    provideEnvironmentNgxMask()
  ]
})
export class SplashModule { }
