<form #addOrUpdatePhoneForm="ngForm" autocomplete="off">
  <div class="col-sm">
    <div class="form-group">
      <label for="number">Phone Number</label>
      <input #number="ngModel" class="form-control" id="number" name="number" numericOnly
        [(ngModel)]="addOrUpdatePersonsPhoneCommand.number" [value]="addOrUpdatePersonsPhoneCommand" required
        pattern="^[0-9]{10}$">
      <div validationErrors [for]="number.control" [errorEmitter]="errorEmitter"></div>
    </div>
  </div>

  <div class="row">
    <div class="col-md">
      <div class="form-group">
        <label for="phoneCodeId">Phone Type</label>
        <ng-select #phoneCodeId="ngModel" id="phoneCodeId" name="phoneCodeId" labelForId="phoneCodeId" [closeOnSelect]="true"
          [multiple]="false" required [(ngModel)]="addOrUpdatePersonsPhoneCommand.phoneCodeId"
          (change)="removeUnknown()" placeholder="Select a phone type...">
          <ng-option *ngFor="let phoneOption of filteredPhoneOptions" [value]="phoneOption.phoneCodeId"
            [disabled]="phoneOption.isDisabled || phoneOption.description.toLowerCase() === 'unknown'"
            style="display: none">{{phoneOption.description}}</ng-option>
        </ng-select>
        <div validationErrors [for]="phoneCodeId.control" [errorEmitter]="errorEmitter"></div>
      </div>
    </div>

    <div class="col-md">
      <div class="form-group">
        <label for="isMobile">Mobile</label>
        <ng-select #isMobile="ngModel" id="isMobile" name="isMobile" labelForId="isMobile" [items]="yesNoOptions" [closeOnSelect]="true"
          [multiple]="false" [bindValue]="'value'" [bindLabel]="'text'" required
          [(ngModel)]="addOrUpdatePersonsPhoneCommand.isMobile" placeholder="Select if phone is mobile...">
        </ng-select>
        <div validationErrors [for]="isMobile.control" [errorEmitter]="errorEmitter"></div>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <button *ngIf="addOrUpdatePersonsPhoneCommand.personPhoneNumberId" class="btn btn-sm btn-danger float-start"
        (click)="openConfirmationModal()" type="button">Delete</button>
      <div class="float-end">
        <button class="btn btn-sm btn-outline-secondary me-2" (click)="cancelAddPhone()" type="button">Cancel</button>
        <button class="btn btn-sm btn-primary" (click)="onSubmitPhone()" type="submit">Save</button>
      </div>
    </div>
  </div>
</form>
