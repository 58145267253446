<div class="form-group">
  <div class="row align-items-center">
    <div class="col-lg d-flex">
      <h3 class="h5 fw-normal">Email</h3>
      <span class="d-md-none">
        <i class="add-circle-icon ms-1 text-primary" (click)="enableAddEmail()"></i>
      </span>
    </div>
    <div class="col-lg text-end d-none d-md-block">
      <div>
        <button class="btn btn-primary" (click)="enableAddEmail()">Add</button>
      </div>
    </div>
  </div>
  <ul class="list-group mt-3">
    @if (!dataLoaded) {
      <li>
        <ngx-skeleton-loader *ngIf="!dataLoaded" count="1" [theme]="{height: '5rem'}"></ngx-skeleton-loader>
      </li>
    }
    @else if (!addEmailMode && dataLoaded) {
      <li class="list-group-item" *ngIf="emails.length === 0 && !addEmailMode">
        <p class="h6 fw-normal m-1 py-3">
          There are no email addresses on file for this educator
        </p>
      </li>
      <li class="list-group-item clickable" *ngFor="let email of emails" (click)="loadEditForm(email)"
        [ngClass]="emailItemClasses(email)">
        <div *ngIf="!!email" class="d-flex justify-content-between my-1">
          <div>
            <span class="h6 fw-normal me-2">{{email.email}}</span><span class="badge-md badge-info">{{email.description}}</span>
          </div>
          <div *ngIf="email.personEmailId != this.selectedEmail?.personEmailId">
            <i certTooltip="Edit" class="edit-icon-lg"></i>
          </div>
        </div>
      </li>
    }
    @else if (addEmailMode && dataLoaded) {
      <li class="list-group-item">
        <edportal-educator-contact-email-form [email]="selectedEmail" [emailOptions]="emailOptions"
          (emailChanged)="handleFormEvent($event)"></edportal-educator-contact-email-form>
      </li>
    }
  </ul>
</div>
