<div class="text-center">
  <h1 class="h4">Identity Verification</h1>
  <div class="lead">
    <ng-container *ngIf="isVerified">
      Your account has been verified! Click the button below to continue to the educator portal.
    </ng-container>
    <ng-container *ngIf="!isVerified">
      We weren't able to find an educator record with the details that you provided. Please <a href="#" (click)="onTryAgain($event)">try again</a> or contact ADE for assistance.
    </ng-container>
  </div>
</div>
<div class="verified-icon mx-auto d-flex justify-content-center">
  <ng-container *ngIf="isVerified">
    <i class="bi bi-check-circle text-success"></i>
  </ng-container>
  <ng-container *ngIf="!isVerified">
    <i class="bi bi-x-circle text-danger"></i>
  </ng-container>
</div>
<div class="d-grid">
  <button class="btn btn-primary mx-2" (click)="onContinue()">Continue</button>
</div>
