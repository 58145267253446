import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'edportal-signout-redirect-callback',
  template: '<div></div>'
})
export class SignoutRedirectCallbackComponent {
  constructor(
    private authService: AuthService,
    private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.authService.completeLogout().then(() => {
      this.router.navigate(['/launch'], { replaceUrl: true });
    });
  }
}
