import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthClient } from 'src/shared/services/api.service';
import { map } from 'rxjs';

export const resolveStepTwoRouteGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  return inject(AuthClient).getEducatorVerificationInformation().pipe(
    map(result => {
      if (result.fingerprintCardOnFile) {
        return router.parseUrl('/verify/two-dps');
      } else if (result.contactInfoOnFile) {
        return router.parseUrl('/verify/two-contact');
      } else {
        return true;
      }
    })
  );
};
