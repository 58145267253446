import { Injectable } from '@angular/core';
import { FileResponse } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {


  openFileInNewTab(fileResponse: FileResponse | null): void {
    if (fileResponse) {
      const blob = new Blob([fileResponse.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.focus();
      }

      URL.revokeObjectURL(url);
    }
  }

  openPdfURLInNewTab(pdfURL: string | undefined): void {
    if (pdfURL) {
      const newTab = window.open(pdfURL, '_blank');
      if (newTab) {
        newTab.focus();
      }
    }
  }

  renameAndDownloadPdfFile(fileResponse: FileResponse, fileName: string): void {
    const blob = new Blob([fileResponse.data], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  }
}
