import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'edportal-month-year-input',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  templateUrl: './month-year-input.component.html',
  styles: ``
})
export class MonthYearInputComponent {
  @Input() propertyName: string = '';

  @Input() model!: string;

  @Input() name!: string;

  @Input() minDate!: string;

  @Input() maxDate!: string;

  @Input() defaultDate!: string;

  @Input() isDisabled: boolean = false;

  @Input() isRequired: boolean = false;

  isFieldValid = true;

  modelString: string = '';

  @Output() modelChange = new EventEmitter<string>();

  errorEmitter = new EventEmitter<any>();

  flatPicker!: any;

  updateModel(date: string) {
    this.model = date;

    this.modelChange.emit(this.model);
  }

  onKeyDown(key: any) {
    if (key.which !== 8) {
      const numChars = key.target.value.length;
      if (numChars === 2) {
        let thisVal = key.target.value;
        thisVal += '/';
        key.target.value = thisVal;
      }
    }
  }
}
