<div class="container-fluid h-100 d-flex align-items-center justify-content-center splash-bg">
  <div class="card card-splash">
    <div class="card-body">
      <header class="text-center my-4">
        <img src="/assets/azde-horizontal-logo-new.png" alt="AZDE Logo" title="Arizona Department of Education" class="w-100" loading="lazy">
      </header>
      <main role="main">
        <router-outlet></router-outlet>
      </main>
      <div *ngIf="isLoggedIn" class="text-center mt-3">
        <span class="pe-1">Logged in as {{loggedInEmail}}</span>
        <span>(<a class="link-opacity-25-hover" href="#" (click)="onLogout($event)">Logout</a>)</span>
      </div>
    </div>
    <footer class="card-footer text-center">
      <p class="card-text"><small>© 2023 ADE. All Rights Reserved.</small></p>
      <p *ngIf="environment && environment!=='Production'" class="alert alert-warning">You are in the {{environment | uppercase}} environment.</p>
    </footer>
  </div>
</div>
