<div class="text-center mb-3">
  <h1 class="h4">Identity Verification</h1>
  <div class="lead">
    Before you can get started, we need to verify your identity so that we can locate your existing educator
    information.
  </div>
</div>
<form #stepOneForm="ngForm" autocomplete="off">
  <div class="form-group">
    <label class="form-label" for="ssn">Social Security Number</label>
    <input #ssn="ngModel" [(ngModel)]="stepOneCommand.ssn" class="form-control" name="ssn" id="ssn" type="text"
      mask="000-00-0000" [dropSpecialCharacters]="true" [validation]="false" />
    <div validationErrors [for]="ssn.control" [errorEmitter]="errorEmitter"></div>
  </div>
  <div class="form-group">
    <label class="form-label" for="dateOfBirth">Date of Birth</label>
    <input #dateOfBirth="ngModel" [(ngModel)]="stepOneCommand.dateOfBirth" class="form-control" name="dateOfBirth"
      id="dateOfBirth" type="text" mask="M0/d0/0000" [outputTransformFn]="dateParser" />
    <div validationErrors [for]="dateOfBirth.control" [errorEmitter]="errorEmitter"></div>
  </div>
</form>
<div class="d-grid mt-4">
  <button class="btn btn-primary mx-2" (click)="onContinue()">Continue</button>
</div>
