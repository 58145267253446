import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError, map } from 'rxjs';
import { AuthClient } from 'src/shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class RequireConfirmedEducatorGuard  {
  constructor(private authClient: AuthClient, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authClient.getEducatorInfo().pipe(
      map(res => {
        if (!res.isConfirmed) {
          return this.router.createUrlTree(['/verify/one']);
        }
        else {
          return true;
        }
      }),
      catchError((error) => {
        console.error(error);
        return [false];
      }))
  }

}
