import { Component, EventEmitter, Input, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from 'src/shared/confirmation-modal/confirmation-modal.component';
import { FormEvents } from 'src/shared/constants';
import { SelectListOption, yesNoOptions } from 'src/shared/models/SelectListOptions';
import { AddOrUpdatePersonsPhoneCommand, DeletePersonsPhoneCommand, ProfileClient, PersonPhoneDto, PhoneCodeDto } from 'src/shared/services/api.service';
import { catchValidationErrors } from 'src/shared/validation/validator-utilities';

@Component({
  selector: 'edportal-educator-contact-phone-form',
  templateUrl: './educator-contact-phone-form.component.html',
  styleUrls: ['./educator-contact-phone-form.component.scss']
})
export class EducatorContactPhoneFormComponent {
  @Input() phoneNumber!: PersonPhoneDto | undefined;
  @Input() phoneOptions!: PhoneCodeDto[];
  @Output() phoneChanged = new EventEmitter<FormEvents>();

  @ViewChild('addOrUpdatePhoneForm') form!: NgForm;
  errorEmitter = new EventEmitter<any>();

  yesNoOptions: SelectListOption<boolean>[] = yesNoOptions;
  addOrUpdatePersonsPhoneCommand!: AddOrUpdatePersonsPhoneCommand;
  filteredPhoneOptions!: PhoneCodeDto[];

  constructor(
    private profileClient: ProfileClient,
    private modalService: NgbModal,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.assignCommandFromPhoneNumber();
    this.filterPhoneOptions();
  }

  ngAfterViewInit() {
    this.renderer.selectRootElement('#number').focus();
  }

  assignCommandFromPhoneNumber() {
    this.addOrUpdatePersonsPhoneCommand = {
      personPhoneNumberId: this.phoneNumber?.personPhoneNumberId,
      phoneCodeId: this.phoneNumber?.phoneCodeId ?? undefined,
      number: this.phoneNumber?.phoneNumber ?? undefined,
      isMobile: this.phoneNumber?.isMobile ?? undefined
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.phoneNumber) {
      this.assignCommandFromPhoneNumber();
      this.filterPhoneOptions();
    }
  }

  cancelAddPhone() {
    this.phoneChanged.emit(FormEvents.Cancelled);
  }

  onSubmitPhone() {
    const isAdd = !this.addOrUpdatePersonsPhoneCommand?.personPhoneNumberId;

    const observable = isAdd
      ? this.profileClient.addPersonsPhone(this.addOrUpdatePersonsPhoneCommand)
      : this.profileClient.updatePersonsPhone(this.addOrUpdatePersonsPhoneCommand);

    observable
      .pipe(catchValidationErrors(this.form, this.errorEmitter))
      .subscribe({
        next: () => {
          this.phoneChanged.emit(FormEvents.Successful);
        },
        error: () => {
          this.phoneChanged.emit(FormEvents.Error);
        }
      });
  }

  openConfirmationModal() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      backdrop: 'static',
      size: 'sm',
      centered: true,
    });
    modalRef.componentInstance.header = 'Delete Phone Number';
    modalRef.componentInstance.message = 'Are you sure you want to delete this phone number? Operation can not be undone.';

    modalRef.result.then((result) => {
      if (result) {
        this.deleteExistingPhone();
      }
    });
  }

  deleteExistingPhone() {
    const deletePersonsPhoneCommand: DeletePersonsPhoneCommand = {
      personPhoneNumberId: this.phoneNumber!.personPhoneNumberId
    };

    this.profileClient.deletePersonsPhone(deletePersonsPhoneCommand).subscribe({
      next: () => {
        this.phoneChanged.emit(FormEvents.Successful);
      },
      error: () => {
        this.phoneChanged.emit(FormEvents.Error);
      }
    });
  }

  filterPhoneOptions() {
    if (typeof this.phoneNumber?.personPhoneNumberId === 'undefined') {
      this.filteredPhoneOptions = this.phoneOptions.filter(x => x.description.toLowerCase() !== 'unknown');
    } else {
      this.filteredPhoneOptions = this.phoneOptions;
    }
  }

  removeUnknown() {
    this.phoneOptions = this.phoneOptions.filter(x => x.description.toLowerCase() !== 'unknown');
  }
}
