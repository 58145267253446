<header>
  <nav class="navbar navbar-expand-md navbar-dark bg-primary fixed-left">
    <div class="container-fluid d-md-block">
      <a class="navbar-brand" href="#"><img src="/assets/azde-seal-blank.png" width="50" class="me-2"
          alt="Arizona Department of Education Seal" title="Arizona Department of Education" />Educator Portal</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggle"
        aria-controls="navbarToggle" aria-expanded="false" aria-label="Toggle navigation">
        <i class="bi bi-list"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarToggle">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-profile">
            <strong>{{educatorName}}</strong>
            <br />
            <span class="explanation" [edPortalTooltip]="einExplanation"><strong>EIN:</strong> {{ein}}</span>
            <br />
            <span [ngSwitch]="fingerprintValidityStatus" [edPortalTooltip]="fingerprintValidityExplanation">
              <span *ngSwitchCase="fingerprintCardValidityStatus.NotOnFile">
                <span class="fw-bold me-1">IVP Fingerprint:</span>Not On File<i class="bi bi-stop-circle ps-1 text-danger"></i></span>
              <span *ngSwitchCase="fingerprintCardValidityStatus.Valid">
                <span class="fw-bold me-1">IVP Fingerprint:</span>Valid<i class="bi bi-check-circle ps-1 text-success"></i></span>
              <span *ngSwitchCase="fingerprintCardValidityStatus.Pending">
                <span class="fw-bold me-1">IVP Fingerprint:</span>Pending<i class="bi bi-exclamation-circle ps-1 text-warning"></i></span>
              <span *ngSwitchCase="fingerprintCardValidityStatus.NotValid">
                <span class="fw-bold me-1">IVP Fingerprint:</span>Not Valid<i class="bi bi-stop-circle ps-1 text-danger"></i></span>
              <span *ngSwitchCase="fingerprintCardValidityStatus.Expired"><span class="fw-bold me-1">IVP Fingerprint:</span>
                Expired<i class="bi bi-x-circle ps-1 text-danger"></i></span>
              <span *ngSwitchDefault><span class="fw-bold me-1">IVP Fingerprint:</span>
                Not On File<i class="bi bi-stop-circle ps-1 text-danger"></i></span>
            </span>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/home']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
              class="nav-link" aria-current="page"><i class="bi bi-house me-2"></i>Home</a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/my-certificates']" [routerLinkActive]="['active']" class="nav-link"><i
                class="bi bi-journal-bookmark me-2"></i>My Certificates</a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/applications']" [routerLinkActive]="['active']" class="nav-link"><i
                class="bi bi-file-earmark-text me-2"></i>Applications</a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/notifications']" [routerLinkActive]="['active']" class="nav-link"><i
                class="bi bi-send me-2"></i>Notifications</a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/upload-documents']" [routerLinkActive]="['active']" class="nav-link"><i
                class="bi bi-file-earmark-arrow-up me-2"></i>Upload Documents</a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/profile']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }"
              class="nav-link" aria-current="page"><i class="bi bi-person-circle me-2"></i>Educator Profile</a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/contact-us']" [routerLinkActive]="['active']" class="nav-link"><i
                class="bi bi-phone me-2"></i>Contact Us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" (click)="logout($event)"><i class="bi bi-box-arrow-right me-2"></i>Logout</a>
          </li>
          <div *ngIf="environment && environment!=='Production'">
          <hr>
          <li class="nav-item">
            <edportal-issue-collector/>
          </li>
        </div>
        </ul>
      </div>
    </div>
  </nav>
</header>
