import { Component, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, finalize } from 'rxjs';
import { PublicEducatorPositionsDto, GetPublicEducatorSearchQuery, PublicEducatorDto, PublicPortalClient, PublicSearchEducatorDto, SortDirection, SortField, TeachingGradeDto } from 'src/shared/services/api.service';
import { catchValidationErrors } from 'src/shared/validation/validator-utilities';

@Component({
  selector: 'edportal-home',
  templateUrl: './public-search-form.component.html',
  styleUrl: './public-search-form.component.scss'
})
export class PublicSearchFormComponent {
  constructor(private publicPortalClient: PublicPortalClient) { }
  @ViewChild('publicSearchForm') form!: NgForm;

  educatorPositions: PublicEducatorPositionsDto[] = [];
  teachingGrades: TeachingGradeDto[] = [];
  educators: PublicEducatorDto[] = [];
  maxPageSize!: number;
  educators$!: Observable<PublicSearchEducatorDto>;
  fiscalYear!: string;

  loading: boolean = false;
  initialLoad: boolean = true;

  errorEmitter = new EventEmitter<any>();

  publicSearchEducator: GetPublicEducatorSearchQuery = {
    lastName: '',
    firstName: '',
    ein: undefined,
    teachingGrade: undefined,
    positionId: undefined,
    pageNumber: 1,
    pageSize: 20,
    sortBy: SortField.LastName,
    sortDirection: SortDirection.ASC
  };

  ngOnInit() {
    this.getPositions();
    this.getTeachingGrades();
  }

  searchEducator() {
    this.loading = true;
    this.initialLoad = false;
    this.educators = [];

    this.publicPortalClient.searchEducator(this.publicSearchEducator)
      .pipe(
        catchValidationErrors(this.form, this.errorEmitter),
        finalize(() => this.loading = false))
      .subscribe(x => {
        this.educators = x.educators;
        this.maxPageSize = x.maxPageNumber;
        this.fiscalYear = x.fiscalYear;
      });
  }

  onReset() {
    this.publicSearchEducator = {
      lastName: '',
      firstName: '',
      ein: undefined,
      teachingGrade: undefined,
      positionId: undefined,
      pageNumber: 1,
      pageSize: 20,
      sortBy: SortField.LastName,
      sortDirection: SortDirection.ASC
    };

    this.initialLoad = true;
    this.educators = [];
  }

  getPositions() {
    this.publicPortalClient.getEducatorPositions().subscribe(x => this.educatorPositions = x);
  }

  getTeachingGrades() {
    this.publicPortalClient.getTeachingGrades().subscribe(x => this.teachingGrades = x);
  }

  onSortChanged(event: { sortField: SortField, sortDirection: SortDirection }) {
    this.publicSearchEducator.sortBy = event.sortField;
    this.publicSearchEducator.sortDirection = event.sortDirection;
    this.searchEducator();
  }

  onPrevPage() {
    this.publicSearchEducator.pageNumber = --this.publicSearchEducator.pageNumber;
    this.searchEducator();
  }

  onNextPage() {
    this.publicSearchEducator.pageNumber = ++this.publicSearchEducator.pageNumber;
    this.searchEducator();
  }

  onSearch() {
    this.publicSearchEducator.pageNumber = 1;
    this.publicSearchEducator.sortDirection = SortDirection.ASC;

    this.searchEducator();
  }
}
