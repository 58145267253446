import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigServiceProvider } from './services/config.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { FormLevelValidationErrorsDirective } from './validation/form-level-validation-errors.directive';
import { ValidationErrorsDirective } from './validation/validation-errors.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PhoneNumberPipe } from './phone-number.pipe/phone-number.pipe';
import { OrderByPipe } from './order-by.pipe/order-by.pipe';
import { MARKED_OPTIONS, MarkdownModule } from 'ngx-markdown';
import { markedOptionsFactory } from './markdown-options/markdown-factories';
import { ProgressStepperComponent } from './progress-stepper/progress-stepper.component';
import { PendingAppBannerComponent } from './pending-app-banner/pending-app-banner.component';
import { NumericOnlyDirective } from './directives/numeric-only.directive';
import { MonthYearInputComponent } from './month-year-input/month-year-input.component';
import { UploaderComponent } from './uploader/uploader.component';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { EducatorContactAddressComponent } from 'src/shared/educator-contact/educator-contact-address/educator-contact-address.component';
import { EducatorContactAddressFormComponent } from 'src/shared/educator-contact/educator-contact-address/educator-contact-address-form/educator-contact-address-form.component';
import { EducatorContactPhoneComponent } from 'src/shared/educator-contact/educator-contact-phone/educator-contact-phone.component';
import { EducatorContactPhoneFormComponent } from 'src/shared/educator-contact/educator-contact-phone/educator-contact-phone-form/educator-contact-phone-form.component';
import { EducatorContactEmailComponent } from 'src/shared/educator-contact/educator-contact-email/educator-contact-email.component';
import { EducatorContactEmailFormComponent } from 'src/shared/educator-contact/educator-contact-email/educator-contact-email-form/educator-contact-email-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from './loader/loader.component';
import { StandardHeaderComponent } from './standard-header/standard-header.component';


@NgModule({
  declarations: [
    FormLevelValidationErrorsDirective,
    ValidationErrorsDirective,
    TooltipDirective,
    ConfirmationModalComponent,
    ProgressStepperComponent,
    PhoneNumberPipe,
    OrderByPipe,
    PendingAppBannerComponent,
    NumericOnlyDirective,
    MonthYearInputComponent,
    UploaderComponent,
    EducatorContactAddressComponent,
    EducatorContactAddressFormComponent,
    EducatorContactPhoneComponent,
    EducatorContactPhoneFormComponent,
    EducatorContactEmailComponent,
    EducatorContactEmailFormComponent,
    LoaderComponent,
    StandardHeaderComponent
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    NgxSkeletonLoaderModule.forRoot({
      animation: 'progress',
      loadingText: 'Loading...',
      ariaLabel: 'Loading'
    }),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: markedOptionsFactory,
      }
    }),
    NgxMaskDirective,
    NgxMaskPipe,
    NgbModule
  ],
  providers: [
    ConfigServiceProvider,
    provideEnvironmentNgxMask()
  ],
  exports: [
    NgSelectModule,
    FormsModule,
    FormLevelValidationErrorsDirective,
    ValidationErrorsDirective,
    TooltipDirective,
    NgxSkeletonLoaderModule,
    PhoneNumberPipe,
    OrderByPipe,
    MarkdownModule,
    ProgressStepperComponent,
    PendingAppBannerComponent,
    NumericOnlyDirective,
    MonthYearInputComponent,
    UploaderComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    EducatorContactAddressComponent,
    EducatorContactAddressFormComponent,
    EducatorContactPhoneComponent,
    EducatorContactPhoneFormComponent,
    EducatorContactEmailComponent,
    EducatorContactEmailFormComponent,
    NgbModule,
    LoaderComponent,
    StandardHeaderComponent,
    CommonModule
  ]
})
export class SharedModule { }
