export interface ServiceItemLaunchConfig {
  position: number;
  title: string;
  type: ServiceType;
}

// These correspond to the ServiceCode.
export enum ServiceType {
  None = '0',
  Renew = '74',
  RemoveDeficiency = '77',
  AddCertificate = '25',
  AddArea = '23',
  AddEndorsement = '21',
  ChangeName = '75',
  RequestCopyOfCert = '76',
  IRCertificate = '88',
  Extension = '120'
}

export function toServiceType(serviceTypeVal: string) {
  switch (serviceTypeVal) {
    case '74':
      return ServiceType.Renew;
    case '77':
      return ServiceType.RemoveDeficiency;
    case '25':
      return ServiceType.AddCertificate;
    case '23':
      return ServiceType.AddArea;
    case '21':
      return ServiceType.AddEndorsement;
    case '75':
      return ServiceType.ChangeName;
    case '76':
      return ServiceType.RequestCopyOfCert;
    case '88':
      return ServiceType.IRCertificate;
    case '120':
      return ServiceType.Extension;
    default:
      return ServiceType.None;
  }
}
