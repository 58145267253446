import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { PublicSearchFormComponent } from './public-search-form/public-search-form.component';
import { EducatorDetailComponent } from './educator-detail/educator-detail.component';

const moduleRoutes: Routes = [
  {
    path: '', component: PublicLayoutComponent,
    children: [
      { path: '', component: PublicSearchFormComponent, title: 'Public Portal' },
      { path: 'educator-detail/:personId', component: EducatorDetailComponent, title: 'Educator Detail' },
    ]
  }
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(moduleRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class PublicRoutingModule { }
