import { Component, Input } from '@angular/core';
import { CertificateStatusCodes } from 'src/shared/constants';
import { PublicEducatorCertificateDto } from 'src/shared/services/api.service';

@Component({
  selector: 'edportal-educator-certificate-detail',
  templateUrl: './educator-certificate-detail.component.html',
  styles: ``
})
export class EducatorCertificateDetailComponent {
  @Input() certificate!: PublicEducatorCertificateDto;

  certificateStatusCodes = CertificateStatusCodes;

  constructor() {}
}
